$color-violet: #5b38b9;
$color-bg: rgba(255, 255, 255, 0.17);
$color-green-disabled: #6dbb94;
$color-lightGray4: #e9eaeb;
$color-gray2: #151c2b;
$color-gray3: #4d4d4d;
$color-lightGray: #343642;
$color-lighterGray: #5c5e73;
$color-lightGray2: rgb(194, 194, 194);

.tournament-variants {
  margin: 30px;

  .header {
    margin-bottom: 20px;
  }

  .logout {
    text-align: right;
  }
}

.btn-switch {
  width: 100%;

  &.light {
    .btn {
      background-color: $color-bg !important;
    }
  }
}

.tab-container {
  border-bottom: 1px solid $color-bg !important;

  .big {
    height: 70px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    color: #06050f;
    border-bottom: solid 1px #06050f;
    width: auto;

    .btn {
      z-index: 100;
      height: 100%;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      //box-shadow: none !important;
    }
  }
}

.mtt-header-btn {
  @extend .button;

  width: 144px;
  height: 50px;
  border-radius: 24.5px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 20px;

  background-color: $color-violet;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-violet;
  }
}

.payout-btn {
  @extend .mtt-header-btn;

  background-color: #00c162;

  &:hover,
  &:focus,
  &:active {
    background-color: #00c162;
  }
}

.modal-btn {
  width: 5.2rem;
  height: 38px;
  border-radius: 2px;
  background-color: none;
  border: none;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.09px;
  margin-right: 10px;

  &.green {
    background-color: #00c162;
    color: white;
  }

  &.gray {
    background-color: $color-gray2;
    color: white;

    &:disabled {
      background-color: $color-lighterGray;
      color: $color-lightGray2;
    }
  }

  &.purple {
    background-color: black;
    color: $color-violet;
  }

  &.purple-fill {
    background-color: $color-violet;
    color: white;
  }
}

.no-fill {
  background-color: black;
  color: #5b38b9;
  border: solid 1px #5b38b9;
}

.tournament-table {
  margin-top: 20px;

  tr {
    font-family: Roboto;
    font-size: 11px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;

    td {
      vertical-align: middle !important;
    }
  }

  thead {
    tr {
      th {
        border: none;
        vertical-align: middle;
        font-family: Roboto;
        font-size: 9px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.71px;
        background-color: rgba(249, 251, 252, 0.06);
        color: rgba(255, 255, 255, 0.58) !important;

        &:first-child {
          font-size: 10px;
          letter-spacing: 1.71px;
        }
      }
    }
  }

  tbody {
    td {
      border-top: 1px solid rgba(255, 255, 255, 0.17) !important;
    }

    tr {
      &:first-child {
        td {
          border: none !important;
        }
      }
    }
  }
}