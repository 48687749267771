@import "~bootstrap/dist/css/bootstrap.css";
@import "./override-button-bootstrap";
@import "./override-modal";
@import "./override-table";
@import "./menu";
@import "./list";
@import "./components/tournament/floor/Style/tables.scss";
@import "./tournamentVariants.scss";
@import "./administration";
@import url("https://fonts.googleapis.com/css?family=Roboto:100&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$color-black-main: #000000;
$color-black-accent: #0f0f0f;
$color-black-accent2: #161616;
$color-gray: #2f2f2f;
$color-gray2: #151c2b;
$color-gray3: #4d4d4d;
$color-lightGray: #343642;
$color-lighterGray: #5c5e73;
$color-lightGray2: rgb(194, 194, 194);
$color-lightGray4: #e9eaeb;
$color-neon-green: #00c162;
$color-blue: #170a3a;
$color-white: #ffffff;
$color-white-alph06: rgba($color: white,
    $alpha: 0.6,
  );
$color-violet: #5b38b9;
$color-violet2: #2b1760;
$color-violet-05: rgba($color-violet, 0.5);
$color-violet-01: rgba($color-violet, 0.1);
$color-violet-07: rgba($color-violet, 0.7);

$font-small: 300 0.75rem Roboto;
$font-small-bold: 500 0.75rem Roboto;
$padding1: 15px;

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  color: #ffffff;
  background-color: $color-black-main;
}

h4 {
  font-size: 1em;
  font-weight: bold;
}

.lightBox {
  background-color: $color-black-accent;
}

.blueBox {
  background-color: $color-blue;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

input.error {
  border-color: red;
}

.nav-link {
  overflow-wrap: break-word;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: $color-neon-green;
}

.bg-blue {
  background-color: blue;
}

.fr-red {
  color: red;
}

.fr-green {
  color: $color-neon-green;
}

.fr-violet {
  color: $color-violet;
}

.fr-blue {
  color: blue;
}

.underline {
  text-decoration: underline;
}

#root {
  >div {
    &.row {
      max-width: 100%;
    }
  }

  height: 100vh;
}

.colored {
  color: #6442c2;
  margin-top: 13px !important;
}

.colored-u {
  color: #6442c2;
}

.list-card {
  width: 100% !important;
  background: #161616;
  margin-bottom: 10px;
  color: white;
  min-height: 138px;
  height: 100%;

  button {
    color: $color-violet;

    &:hover,
    &:active,
    &:focus,
    &.visited,
    &.disabled {
      color: $color-violet;
    }
  }

  &.seating-locked {
    border: solid 1px $color-violet;
  }

  .lock-label {
    font-size: 0.8rem;
    float: left;
  }

  .lock-btn {
    float: right;
    padding: 0;
  }
}

.list-card a {
  color: inherit;
}

.list-card a:hover {
  text-decoration: none;
}

.mw-800 {
  max-width: 800px;
}

.mw-820 {
  max-width: 820px;
}

.btn-switch {
  .btn {
    background: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0;
    color: $color-gray3;
    font-size: 0.8em;
    outline: none !important;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .active {
    font-size: 1em;
    border-bottom: 3px solid #674dd5 !important;
  }

  &.light {
    .btn {
      background-color: $color-white;
    }

    .active {
      color: $color-violet !important;
    }
  }
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn-dark,
.btn-dark:hover {
  border: none;
  background: none;
}

.btn-translucent {
  background-color: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.btn-icon {
  margin-left: 10px;
  color: #674dd5;
  cursor: pointer !important;

  &.white {
    color: white;
  }
}

.DayPicker {
  width: 100%;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #25264e !important;
  color: #4a90e2;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
  border-radius: 100% !important;
  background-color: #674dd5 !important;
  color: white !important;
}

.Selectable .DayPicker-Day--start::after {
  width: 100% !important;
  height: 100%;
  background: #25264e;
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.Selectable .DayPicker-Day--end {
  border-radius: 50% !important;
  background-color: #674dd5 !important;
  color: white !important;
}

.Selectable .DayPicker-Day--end::before {
  width: 100% !important;
  height: 100%;
  background: #25264e;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.DayPicker-Day--today {
  color: #674dd5 !important;
  font-weight: bolder;
}

.card-icon {
  font-size: 0.9em;
  margin-right: 5px;
  top: -1px;
  position: relative;

  &.tables {
    width: 1.6rem !important;
    margin-left: -3px;
    margin-right: 6px !important;
  }

  &.violet {
    color: $color-violet
  }
}

.navbar .icon {
  color: #674dd5;
}

.header-sub-title {
  font-size: 0.8em;
  top: -2px;
  position: relative;
}

.table-list-modal {
  @extend .custom-scroll;

  .modal-small-width {
    width: 456px !important;
    max-width: 456px !important;
  }

  .modal-content {
    max-height: 90vh;
    margin: 2rem;
  }

  .table-list {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .table-list-filter-option {
    flex: 1 1 auto;
    width: 40%;
    height: 3rem;
    border-radius: 10px;
    background: #9a9a9a;
    margin: 1rem;
    padding: 1rem;

    &.active {
      background: #674dd5;
    }
  }

  .confirm-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 1rem !important;

      &:first-of-type {
        margin-top: 1rem !important;
        margin-right: 1rem !important;
      }
    }
  }
}

.table-seat-dot {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background: $color-gray3;
  display: inline-block;
  margin-right: 2px;
}

.footer-panel {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  height: 70px;
  padding: 10px 0;
  background: #170a3a;
  text-align: -webkit-right;

  .row {
    width: 80%;
  }
}

.footer-panel .active {
  color: #674dd5;
}

.footer-panel-item {
  text-align: center;
  color: #cccccc;
}

.footer-panel-item .icon {
  font-size: 2em;
}

.footer-panel-item span {
  font-size: 0.6em;
  top: -10px;
  position: relative;
}

.footer-panel-placeholder {
  position: relative;
  height: 70px;
  width: 100%;
}

.table-seat-dot-closed {
  background: #161616;
}

.table-seat-dot-active {
  background: #674dd5;
}

.table-newSeat-dot-active {
  background: #fff;
}

.string-filter {
  background: #303030;
  padding: 10px 3%;
  border-radius: 100px;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #9a9a9a;
}

.string-filter:focus {
  outline: none;
}

.string-filter-icon {
  color: #674dd5;
  position: absolute;
  right: 3%;
  top: 10px;
  font-size: 20px;
}

.btn-small-border,
.btn-small-border:hover {
  border-radius: 5px;
  font-size: 0.9em;
}

.outline,
.outline:hover {
  background: #151c2b;
  color: #ffffff;
  border: 2px solid #5b38b9;
}

.btn-success,
.btn-success:hover {
  background: $color-neon-green;
}

.right-select {
  float: right;
  background: none;
  border: none;
  color: #ffffff;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  outline: none;
}

.right-select option {
  background: #ffffff;
  color: #000000;
}

.list-item {
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $color-gray3;
}

.mtt-overview {
  padding-bottom: 15px;
}

// information card
.mtt-card {
  margin: 16px 0;
  display: flex;

  hr {
    width: 100%;
    border-color: $color-gray;
  }

  .card-container {
    background: $color-black-accent;
    padding: 36px 38px 5px 38px;
    border-radius: 3px;
    margin: 0 9px;
    flex: 1;

    .card-title {
      font-size: 24px;
      font-weight: bold;
      padding: 0;
      letter-spacing: 0.2px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .card-item-desc {
      font-size: 14px;
    }

    .card-item-content {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 50px;
    }
  }
}

// tabs
.mtt-tabs {
  border-bottom: 1px solid #2b2b2b;
  margin: 60px 15px 40px 15px;

  .nav-link {
    overflow-wrap: break-word;
    background-color: gray;
    color: black !important;
  }

  a {
    background-color: inherit !important;
    border: none !important;
    font-size: 23px;
    padding: 0 0 25px 0;
    margin: 0 12px;
    transform: translateY(2px);
    opacity: 0.2;
    transition: opacity 0.2s ease;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-bottom: 5px solid #5b38b9 !important;
      color: white;
      font-weight: bold;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.help-search-result {
  margin-bottom: 25px;

  .circle {
    align-self: flex-start;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 10px;
    background: $color-violet;
    color: white;
    text-align: center;
    font-size: small;
    margin: 5px
  }

  .link {
    font-size: medium;
    cursor: pointer;
    text-decoration: underline;
    color: $color-violet;
  }

  .navigation {
    color: #6c757d;
  }

  .point {
    @extend .circle;
    width: 2px;
    height: 2px;
    padding: 3px;
    display: inline-block;
  }

  .point-circle {
    @extend .point;
    width: 2px;
    height: 2px;
    padding: 3px;
    background: inherit;
    border: solid 2px $color-violet;
  }

  .point-circle-margin {
    margin: 0px 5px 0px 20px;
  }

  .square {
    @extend .point;
    border-radius: 0% !important;
    width: 2px;
    height: 2px;
    padding: 3px;
    display: inline-block;
  }

  .square-margin {
    margin: 0px 5px 0px 40px;
  }
}

.help-modal {
  .modal-content {
    align-items: flex-start !important;
    background-color: #0d0327 !important;

    .flow-img {
      width: inherit;
      height: inherit;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .arrow {
      border: solid $color-violet;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
      margin: 0px 5px 0px 20px;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .circle {
      align-self: flex-start;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 10px;
      background: $color-violet;
      color: white;
      text-align: center;
      font-size: small;
      margin: 5px
    }

    .point {
      @extend .circle;
      width: 2px;
      height: 2px;
      padding: 3px;
      display: inline-block;
    }

    .point-circle {
      @extend .point;
      width: 2px;
      height: 2px;
      padding: 3px;
      background: inherit;
      border: solid 2px $color-violet;
    }

    .point-circle-margin {
      margin: 0px 5px 0px 20px;
    }

    .square {
      @extend .point;
      border-radius: 0% !important;
      width: 2px;
      height: 2px;
      padding: 3px;
      display: inline-block;
    }

    .square-margin {
      margin: 0px 5px 0px 40px;
    }

    .btn {
      width: fit-content !important;
      margin-bottom: 25px;
      margin-top: 15px;
    }

    .navigation {
      color: #6c757d;
    }

    .row {
      width: 100%;
    }

    h3 {
      align-self: flex-start;
    }

  }

}

.help-search {
  display: flex;

  .btn {
    width: fit-content;
    padding: 0 0.7rem !important;
    margin: 0 0.5rem !important;
  }
}

.help-cards {
  @extend .btn;
  margin: 0.5em !important;
  vertical-align: middle;
  font-family: "Roboto";
  width: 30%;
  height: 40%;
  border: solid 1px rgba(91, 56, 185, 0.4);
  background: none;
  border-radius: 0.3em;
  display: grid;
  text-align: center;
  padding: 0.3em;
  display: inline-grid;

  .link {
    font-size: medium;
    cursor: pointer;
    text-decoration: underline;
    color: $color-violet;
  }

  input {
    margin: auto;
    width: 120px;
    height: 120px;
  }

  &:hover {
    background-color: rgba(91, 56, 185, 0.4);
    border: solid 2px rgba(91, 56, 185, 0.4);
  }
}

.poker-table {
  @extend .btn;
  vertical-align: middle;
  font-family: "Roboto";
  font-size: 13px;
  width: 34px;
  height: 32px;
  border: solid 1px rgba(91, 56, 185, 0.4);
  text-align: center;
  background: none;
  border-radius: 4px;
  margin: 18px 18px 0px 0px;
}

.icon-locker {
  height: 21px;
  width: 21px;
  border-radius: 3px;
  margin-top: 0;
  top: 75%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: $color-neon-green;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}

.poker-table-locker {
  @extend .poker-table;
  letter-spacing: -0.28px;
  vertical-align: text-top;
  padding: 0px;
  position: relative;
}

.no-table {
  border: none;
}

.base-table {
  background: #5b38b9;
}

.darkred-table {
  border: none;
  background: #a42f3d;
  border-bottom: 7px solid #a42f3d;
}

.red-table {
  border: solid;
  border: 3px solid #e32b41 !important;
}

.green-table {
  border: none;
  background: $color-neon-green;
  border-bottom: 7px solid #03a656;
}

.darkGreen-table {
  border: none;
  background: #005a2d;
  border-bottom: 7px solid #014725;
}

.violet-table {
  border: none;
  background: $color-violet-05;
  border-bottom: 7px solid $color-violet ;
}

.gray-table {
  border: none;
  background: #4a4a4a;
  border-bottom: 7px solid #3b3b3b;
}

.locked-table {
  @extend .green-table;
  border-bottom: none !important;
}

.btn-locker-on {
  font-size: 12px;
  border: none;
  box-shadow: none;
  outline: 0 none !important;
  background-color: rgba(0, 193, 98, 0.31);
  border: solid 2px $color-neon-green;

  &:hover {
    background-color: rgba(0, 193, 98, 0.31);
    border: solid 2px $color-neon-green;
  }

  &:focus {
    background-color: rgba(0, 193, 98, 0.31);
    border: solid 2px $color-neon-green;
  }
}

.btn-locker-off {
  font-size: 12px;
  border: none;
  box-shadow: none;
  outline: 0 none !important;
  background: none;
  border: solid 2px #674dd5;

  &:hover {
    background: none !important;
    border: solid 2px #674dd5;
  }

  &:focus {
    background: none !important;
    border: solid 2px #674dd5;
  }

  &.active {
    background: none !important;
  }
}

.border-line-bottom-active {
  @extend .border-line-bottom;
  border-bottom: 2px solid #5b38b9 !important;
  color: white !important;
}

.border-line-bottom {
  text-align: center;
  padding-bottom: 10px;
  border-style: hidden;
  border-bottom: 2px solid transparent;
  background: none !important;
  margin-left: 15px;
  outline: none;
  display: "inline";
  color: rgba($color: white, $alpha: 0.25);

  &:hover,
  &:focus {
    border-bottom: 2px solid #5b38b9;
    color: white;
  }
}

.line {
  border-bottom: solid 1px rgba(255, 255, 255, 0.17);
  display: block;
  width: 100%;
  margin: 20px 0;
}

.info-text {
  font-size: x-small;
  float: right;
  position: inline;
  text-align: right;
  width: 100%;
}

.info-label {
  position: inline;
  text-align: center;
}

.info-icon {
  font-size: 150%;
  color: #674dd5;
  margin: 0px 5px;
}

.validation-text {
  font-size: x-small;
  position: inline;
  text-align: right;
  width: 100%;
  color: red;
}

.unstyled-button {
  outline: none;
  border: none;
  padding: 0;
  background: none;
  color: $primaryColor;
}

.unstyled-button:focus {
  outline: none;
}

.scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.room {
  width: 168px;
  height: 50px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 2px rgba(56, 56, 56, 0.4);
  color: white;
  background: none;
  text-align: left;
  padding: 0 20px 0 20px;
  margin: 15px 0 0 0;
}

.active-room {
  border: none;
  outline: none;
  width: 168px;
  height: 50px;
  box-shadow: none;
  background-color: #170a3a;
  border-left: 4px solid #5b38b9;
}

.room:hover {
  @extend .active-room;
}

.room:focus {
  outline: none;
}

.room-icon {
  color: #674dd5;
  font-size: 20px;
  vertical-align: middle;
  margin: 10px;
}

.scrollable-space {
  margin-top: 20px;
  white-space: nowrap;
  align-content: center;
  background-color: rgba(52, 54, 66, 0.3);

  .tooltiptext {
    font-size: small;
    visibility: hidden;
    background-color: none;
    color: #674dd5;
    text-align: center;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }

  table {
    position: "inline";
    margin: "0 0 0 1px";
    max-width: 600px;
    align-content: center;
    text-align: center;

    padding: 20px;
    display: inline-block;

    tbody {
      float: left;
      position: inline;
      margin: 0px 0px 0px 1px;

      tr:first-child {
        input {
          margin-top: 0px;
        }
      }

      tr {
        input:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.custom-checkbox {
  margin: 10px;
  position: relative;

  .custom-control-label::before {
    opacity: 0;
  }

  .custom-control-label::after {
    border-radius: 2px;
    border: 1px rgba(192, 192, 192, 0.27) solid !important;
    height: 27px;
    width: 27px;
    left: -2.5rem;
    top: 0;
  }

  .custom-control-input:checked~.custom-control-label::after {
    background-color: #5b38b9;
    border: none;
  }
}

.players-list-modal {
  @extend .custom-scroll;

  height: 90%;
  overflow: hidden !important;


  .footer {
    width: 100%;
  }

  .players {
    overflow: auto;
    overflow-x: hidden;

    .row {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .row:nth-child(odd) {
      background-color: $color-lightGray4;
    }

    .header {
      font-weight: bold;
    }
  }

  .sort {
    .selected {
      color: #5b38b9 !important;
    }

    .btn {
      color: black;
      display: contents;
      width: fit-content !important;
      background: none;
      font-size: 12px;
    }

    label {
      margin: 5px !important;
    }

    margin: 10px;
    width: 100%;
    float: left;
  }

  *>.modal-content {
    padding: 50px !important;
    height: 100% !important;
    max-height: 100% !important;
  }

  .modal-dialog {
    height: 100%;
    max-width: 1000px;
  }

  .modal-title {
    align-self: flex-start;
  }

  .modal-text {
    align-self: flex-start;
  }

  .modal-width {
    min-width: auto !important;
  }

  .button {
    float: left;
    background-color: rgba(91, 56, 185, 0.13);
    color: #5b38b9;
    width: 200px !important;

    &:active {
      outline: none;
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .table {
    display: block;
    empty-cells: show;

    tr,
    th {
      color: black;
      font-family: Roboto;
      font-size: 11px;
      width: 100%;
      display: flex;
    }

    thead {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      color: rgba(82, 82, 82, 0.6);
      background-color: rgba(187, 187, 187, 0.1);
      position: relative;
      display: block;
      width: 100%;

      tr {
        th {
          border: none;
          color: rgba(82, 82, 82, 0.6);

          &:first-child {
            color: black;
            letter-spacing: 1.71px;
          }
        }
      }
    }

    tbody {
      width: 100%;
      display: block;
      overflow: auto;
      height: 500px;
      position: relative;
      overflow-y: auto;

      td {
        width: inherit;
      }

      tr {
        &:first-child {
          td {
            border: none;
          }
        }
      }
    }
  }

  &.dark {
    .table {
      display: block;
      empty-cells: show;

      tr,
      th {
        color: $color-lightGray2;
      }

      thead {
        tr {
          th {
            &:first-child {
              color: $color-white;
            }
          }
        }
      }

      th,
      td {
        border-top: 1px solid $color-lightGray;
      }
    }

    .sort {
      .btn {
        color: $color-white;
      }
    }
  }
}

.date-filter-modal {
  .modal-width {
    max-width: 30% !important;
  }

  .modal-content {
    padding: 50px !important;
  }

  .modal-title {
    align-self: flex-start !important;
  }

  .apply {
    width: 170px !important;
    margin-right: 10px !important;
  }

  .clear {
    float: right;
    width: 110px !important;
    color: #5b38b9;
    margin-left: 10px !important;

    &:focus {
      border: #5b38b9 2px solid;
    }

    &:hover {
      color: white;
    }
  }
}

.input-right-icon-control {
  background: white !important;
  float: right;
  width: 300px;

  .input-part {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background: white;
    line-height: 50px;
    border: 1px solid #ebecf0;
    border-right: none;
    box-shadow: 0px 2px 0px 0px #d9dadd;
    font-weight: bold;
    font-size: 13px;
    color: $color-black-main;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-weight: bold;
    }

    &:focus {
      color: $color-black-main;
      background: white;
      border: 1px solid #ebecf0;
      border-right: none;
      box-shadow: 0px 2px 0px 0px #d9dadd;
    }
  }

  .icon-part {
    background-color: white;
    border-top-right-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
    border: 1px solid #ebecf0;
    border-left: none;
    box-shadow: 0px 2px 3px 0px #d9dadd;
  }

  .input-group-text {
    color: #5b38b9;
  }
}

.input-right-icon-control-black {
  background: $color-black-main !important;
  float: right !important;
  width: 300px;

  .input-part {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    background: $color-gray;
    line-height: 50px;
    border-right: none;
    font-weight: bold;
    font-size: 13px;

    &::placeholder {
      font-weight: bold;
    }

    &:focus {
      background: $color-gray;
      border-right: none;
    }
  }

  .icon-part {
    background-color: $color-gray;
    border-top-right-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
    border: none;
  }

  .input-group-text {
    color: #5b38b9;
  }
}

.custom-scroll {
  ::-webkit-scrollbar-thumb {
    background: #5b38b9;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }
}

.underlined-btn {
  background: none;
  margin: 0px !important;
  padding: 0px !important;
  width: auto !important;
  height: fit-content;
  color: $color-violet;
  text-decoration-line: underline;
}

.underlined-btn:hover {
  @extend .underlined-btn;
}

.underlined-btn:focus {
  @extend .underlined-btn;
}

.player-row {
  @extend .table-detail-main-title;

  background: none;
  display: flex;
  flex-wrap: nowrap;

  .col1 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .col2 {
    flex-grow: 1;
  }

  .col3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.seating-table {
  align-content: center;
  font-size: 1.1em;
  margin: 10px;
  padding: 5px;
  background-color: $color-black-accent2;

  .row {
    align-items: center;
    margin: 10px;

    &:nth-child(3) {
      margin-top: 30px;
    }
  }

  .swap {
    width: fit-content;
    font-size: 0.8rem;
  }

  .table-name {
    font-size: 1.5em;
  }
}

.reseat-player-seats {
  @extend .custom-scroll;

  .modal-small-width {
    width: 456px !important;
    max-width: 456px !important;
  }

  .modal-title {
    padding-left: 20px;
    align-self: flex-start;
    color: white;
  }

  .seats {
    width: 100%;
    height: 500px;
    overflow: auto;
  }

  .modal-content {
    color: white !important;
    background-color: rgb(21, 28, 43);

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }

    .btn {
      border-radius: 0px;
      width: fit-content;
      margin-top: 0px !important;
      margin-right: 10px;
    }
  }
}

.reseat-player-tables {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .modal-title {
    align-self: flex-start;
    color: white;
    margin-right: 30px;
  }

  .modal-content {
    background-color: rgb(21, 28, 43);

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }

    .tables {
      width: auto !important;
    }
  }

  .list-card {
    background-color: rgba($color: white, $alpha: 0.02);
    color: white;

    .btn {
      @extend .underlined-btn;
    }
  }
}

.reseat-all-players {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .modal-title {
    margin-right: 20px;
  }

  .btn {
    width: fit-content !important;
    margin-right: 10px !important;
  }

  .small {
    width: 80px !important;
  }

  .validation-txt {
    color: red;
    font-size: 12px;
  }
}

#editPayouts {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .tournamentHeader {
    margin: $padding1 !important;
  }

  .payout-body {
    @extend .custom-scroll;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    padding-left: 0;
    flex-grow: 1;
    height: fit-content;
    padding-bottom: 70px;

    .payout-body-content {
      width: 100%;

      &.container {
        max-width: 100% ! important;
      }
    }

    >* .row {
      height: 2.5rem;
    }

    .parent-row {
      margin: 0px;
      height: auto;
    }

    @media (max-width: 768px) {
      .parent-row {
        margin-left: -15px;
        margin-right: -15px;
        height: auto;

        .lightBox {
          margin-left: -15px;
          margin-right: -15px
        }
      }

      .col-3 {
        padding-right: 0 !important;
      }

      h1 {
        font-size: 23px;
        letter-spacing: 0.76px;
      }
    }
  }

  .confirm-buttons {
    .button {
      width: 30%;
      margin: 15px;
    }
  }

  .graySeparator {
    margin-top: 10px;
  }
}

.template-payout-structure {
  //@extend .custom-scroll;
  padding: 20px;
  margin-bottom: 15px !important;

  .info-text {
    font-size: 12px;
    text-align: left;
  }

  .allocated {
    margin-bottom: 20px !important;
  }

  .row {
    margin: 0;
  }

  .payouts-table {
    //max-height: 400px;
    overflow-y: auto;

    .black-input {
      @extend .black-input;
      background-color: $color-gray !important;
    }
  }

  .black-input {
    background-color: transparent;
    color: white;
    border-color: transparent;
    width: 100%;
    height: auto;

    &:focus {
      height: auto;
      border-radius: 5px;
      border-color: $color-violet !important;
    }
  }

  .highlighted {
    width: auto !important;
    background-color: $color-gray;
  }
}

.payout-dropdown {
  margin-right: 8px;
  width: 130px;
}

.edit-tables {
  @extend .custom-scroll;

  .modal-content {
    .legend-text {
      label {
        font-style: normal;
        font-weight: normal !important;
      }
    }

    overflow-y: auto;
    background-color: rgb(21, 28, 43);

    .action-btn {
      width: 100%;
    }

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }

    color: white !important;
  }
}

.table-setting-position {
  .modal-content {
    position: absolute;
    left: calc(50% - 150px);
  }
}

.table-settings-modal {
  .info-text {
    color: red;
    font-size: 12px;
    text-align: center;
  }

  .modal-title {
    padding: 50px 0 0 0;
    align-self: flex-start;
    font-size: 23px !important;
    letter-spacing: 0.76px;
    color: white;
  }

  .modal-content {
    float: right;
    color: white !important;
    padding: 0 0 30px 30px !important;
    background-color: rgb(21, 28, 43);

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }

    label {
      font-size: 12px !important;
      text-align: left;
      font-weight: normal !important;
    }

    .seating {
      width: 100%;
      margin-top: 20px;
    }

    .actions {
      text-align: left;
      padding-left: 20px;
      font-size: 12px !important;
      border-radius: 50px 0px 0px 50px;

      img {
        margin-bottom: 0px !important;
        margin-right: 10px !important;
        max-width: 20px;
      }
    }
  }

  .players {
    padding-right: 20px;
    height: 400px;
    overflow: auto;
    width: 100%;
    overflow-y: auto;

    .col {
      padding-left: 0px;
    }

    color: white !important;
  }

  .info {
    padding-right: 20px;
  }

  &.centered {
    .modal-content {
      float: none;
    }
  }

  &.width400px {
    .modal-dialog {
      width: 400px;
    }
  }
}

.helper-modal {
  .modal-title {
    font-size: 15px !important;
    color: black !important;
  }

  .modal-content {
    padding: 30px 30px 20px 30px !important;
    font-size: 15px !important;
    color: gray !important;
  }

  .modal-width {
    width: 300px;
    max-width: 300px !important;
  }
}

.seat-open-modal {
  @extend .table-settings-modal;

  .modal-width {
    width: 450px;
    max-width: 450px !important;
  }

  .modal-content {
    padding: 0 50px 0 50px !important;

    .helper-icon {
      cursor: pointer !important;
      margin-top: 10px;
    }

    .btn-confirm {
      margin-bottom: 30px;
      font-size: 12px;
    }

    .row {
      width: 100%;

      .col {
        font-size: 15px !important;
        padding: 0px !important;

        .title {
          color: gray;
        }

        .customer {
          color: white;
        }

        .payout-title {
          font-size: 20px;
        }

        .payout {
          font-size: 25px;
        }
      }
    }
  }
}

.choose-player-modal {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .modal-content {
    padding-right: 30px !important;
  }

  .players {
    .player {
      padding: 1rem 1rem 1rem 2rem;

      .line {
        margin: 0;
      }

      .name {
        margin-bottom: 1rem;
      }
    }

    .player-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      button {
        margin-right: 1rem !important;
      }
    }
  }

  .active {
    background-color: unset !important;
  }

  .tournament-tab {
    border-bottom: unset !important;
  }

  .tab-button {
    white-space: nowrap;
    margin-right: 1rem !important;
  }
}

.loader {
  width: 100%;
  min-height: 100px;

  &.active {
    div {
      background-color: transparent;
    }
  }

  &.wv {
    .loader_content {
      color: $color_violet;
    }

    .loader_spinner {
      circle {
        stroke: $color_violet !important;
      }
    }

    .loader_overlay {
      background: white !important;
    }
  }
}

.row {
  &.right {
    justify-content: flex-end;
  }
}

.check-chip-counts-modal {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .btn {
    width: fit-content !important;
    margin-right: 10px !important;
  }

  .small {
    margin-top: 25px !important;
    width: 80px !important;
  }

  .players {
    width: 456px !important;
  }
}

.swap-seats-modal {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .btn {
    width: fit-content !important;
    padding: 0 25px;
    margin-right: 10px !important;
  }

  .small {
    margin-top: 25px !important;
    width: 80px !important;
  }

  .players {
    width: 456px !important;
  }
}

.move-tables-modal {
  @extend .custom-scroll;

  .tables {
    width: 100% !important;
  }

  .modal-content {
    float: right;

    @media (max-width: 768px) {
      padding: 0.3rem !important;

      .table-card {
        margin-left: 0.3rem !important;
      }
    }

  }

  .rooms {
    align-self: flex-start;
  }

  .tables {
    @extend .custom-scroll;
    overflow: auto;
    flex: 1;
    width: 570px;
  }

  .table-card {
    margin-left: 12px;
  }

  .no-tables {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
  }
}

.modal-table {
  thead {
    color: $color-white-alph06;

    th {
      font: $font-small;
      height: 30px;
    }
  }

  thead:first-of-type {
    th {
      height: 20px;
    }
  }

  tbody {
    color: $color-white;
    font: $font-small-bold;
  }

  th,
  td {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.m0 {
  margin: 0px !important;
}

.no-ml {
  margin-left: -15px;
}

.prize-pool {
  @extend .custom-scroll;

  .modal-title {
    align-self: flex-start;
    font-size: 20px !important;
    color: white;
  }

  .modal-small-width {
    width: 456px !important;
    max-width: 456px !important;
  }

  .modal-content {
    @extend .custom-scroll;

    padding: 30px 20px !important;
    max-height: 90vh !important;
    background-color: rgb(21, 28, 43);
    color: $color-lightGray2 !important;

    div {
      margin-bottom: 7px !important;
    }

    .row {
      margin: 3px;
    }

    @media (max-width: 768px) {
      .row {
        margin: 0 -15px;
        font-size: smaller;
      }

      .col-5,
      .col-4,
      .col-1 {
        padding-right: 0.1rem;
      }

      .col-4,
      .col-1 {
        padding-left: 0.1rem;
      }
    }

    .money-pool {
      color: white;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 25px !important;
    }

    .money-pool-title {
      font-size: 23px;
      font-weight: 400;
      margin-bottom: 10px !important;
      color: $color-lightGray4;
    }

    .value {
      color: white;
      font-weight: 500;
    }

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }
  }
}

.player-actions-modal {
  .modal-title {
    align-self: flex-start;
    font-size: 20px !important;
    color: white;
  }

  .modal-small-width {
    width: 520px !important;
    max-width: 520px !important;
  }

  .modal-content {
    .row {
      margin-left: 0;
      align-items: center;
    }

    .col {
      padding-left: 0;
    }

    padding: 50px !important;
    padding-bottom: 0 !important;
    background-color: rgb(21, 28, 43);
    color: white !important;
    font-size: 12px;
    align-items: flex-start !important;

    .modal-close {

      &:before,
      &:after {
        background: white !important;
      }
    }
  }

  .rebuy-addon-row {
    margin-top: 20px;
  }

  .rebuy-addon {
    .rebuy-icon {
      width: 20px;

      path {
        fill: $color-violet;
      }
    }

    a {
      vertical-align: top !important;
    }

    vertical-align: top !important;

    .button {
      vertical-align: top;
      margin: 0px 20px !important;
      width: 101px;
      height: 38px;
      font-size: 11px !important;
      letter-spacing: 0.09px !important;
    }

    .sub-text {
      width: 52px;
      margin-left: 15px;
      margin-right: 15px;
      align-self: center !important;
      display: inline-block;

      a {
        font-size: 15px;
        display: block;
      }

      .sub {
        font-size: 10px;
        opacity: 0.44;
      }
    }
  }

  .action-row {
    align-items: unset !important;
  }

  .action {
    padding: 0 !important;
    display: grid !important;
    margin: 30px 15px !important;
    text-align: center !important;

    a {
      width: 42px;
    }
  }

  .action-btn {
    background-color: #5b38b9 !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 25px !important;
    align-content: center !important;

    .action-icon {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .chipcount {
    width: 209px !important;
    height: 38px !important;
  }

  .chipcount-btn {
    font-size: 11px !important;
    margin: 0 !important;
    width: 115px !important;
    height: 38px !important;
    letter-spacing: 0.09px !important;
    float: right !important;
  }

  text-align: left;

  .modal-footer {
    width: 100%;
    justify-content: center;
  }
}

.action-icon {
  color: white;
  fill: white;
  margin-bottom: 0px !important;
  margin-right: 10px;
  width: 1.6rem;
}

.action-confirm-modal {
  .modal-content {
    p {
      color: $color-lightGray2;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    span {
      color: $color-lightGray2;
    }

    padding: 50px !important;
    padding-bottom: 35px !important;
    text-align: center !important;
  }

  .modal-small-width {
    width: 400px !important;
    max-width: 400px !important;
  }

  .confirm-btn {
    width: 150px !important;
    margin: 0px !important;
  }

  .cancel-btn {
    background-color: white;
    color: $color-violet;
    border-color: rgb(214, 214, 214);
    border-width: 1px;
    width: 100px !important;
  }

  .modal-action-icon {
    width: 35%;
    height: 35%;
    margin-bottom: 1rem;

    path {
      fill: $color-violet;
    }
  }

  .dark {
    background: $color-lightGray;
    color: $color-white;
    border: none;
    box-shadow: none;
  }
}

.action-confirm-disqualify-modal {
  @extend .action-confirm-modal;

  .modal-content {
    padding-bottom: 5px !important;

    p {
      margin-bottom: 15px !important;
    }

    .footer {
      margin-top: 15px;
    }
  }

  small {
    color: $color-lightGray2;
  }

  .chip-count {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;

    span {
      font-weight: bold;
    }

    input {
      width: 100%;
    }
  }
}

.modal-small-width {
  width: auto !important;
  max-width: 350px !important;
}

.property-modal {
  .modal-content {
    margin-left: 0px !important;
    padding: 20px !important;
    min-height: 250px;

    .validation-text {
      font-size: x-small;
      position: inline;
      text-align: left;
      color: red;
    }
  }

  .btn {
    width: 250px !important;
    margin-top: 0 !important;
  }

  input {
    width: 250px !important;
    display: block;
  }
}

.save-tournament-modal {
  .modal-title {
    width: 100%;
    text-align: center;
  }

  .modal-small-width {
    width: 456px !important;
    max-width: 456px !important;
  }

  .content {
    padding-left: 3rem;
    padding-right: 3rem;

    * {
      width: 100% !important;
    }
  }
}

.payout-confirmation-modal {
  .modal-title {
    align-self: flex-start;
  }

  .modal-content {
    padding: 50px !important;
    padding-bottom: 0 !important;
  }

  text-align: left;
  color: #06050f;
  font-weight: 500;
  font-size: 17px;

  .modal-small-width {
    width: 456px !important;
    max-width: 456px !important;
  }

  .modal-small-width-mobile {
    width: 100% !important;
    max-width: 100% !important;
    padding: 20px;
    margin: 0 !important;
  }

  .confirm-btn {
    width: 195px !important;
    height: 49px;
    float: left;
  }

  .light-style {
    color: rgba($color: #06050f, $alpha: 0.35);
    font-size: 13px;
    letter-spacing: 1.21px;
  }

  .payout-label {
    font-size: 24px;
  }

  .payout-value {
    font-size: 37px;
    color: $color-neon-green;
    text-align: right;
  }

  .row {
    margin-left: 0px !important;
    vertical-align: text-bottom;
    margin: 15px;
    width: 100%;
    margin-top: 0px;
  }

  .col {
    vertical-align: text-bottom;
    padding-bottom: 0px;
  }

  .right-align {
    text-align: right;
  }
}

.pending-payout-modal {
  @extend .players-list-modal;

  .mtt-btn {
    margin-top: 0px !important;
    width: 120px !important;
    height: 40px !important;
    font-size: 11px !important;
  }
}

.payout-modal {
  @extend .custom-scroll;

  .modal-content {
    padding: 50px !important;
  }

  .modal-title {
    align-self: flex-start;
  }

  .modal-text {
    align-self: flex-start;
  }

  .input-group {
    align-self: flex-end;
  }

  .text {
    width: auto !important;
    height: 15px;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.21px;
    padding: 5px;
  }

  .green-text {
    @extend .text;
    color: $color-neon-green !important;
    background-color: rgba($color: #00d069, $alpha: 0.05);
    padding: 2px;
  }

  .purple-text {
    @extend .text;
    color: #5b38b9 !important;
    background-color: rgba($color: #5b38b9, $alpha: 0.05);
    padding: 2px;
  }

  .modal-auto-width {
    width: auto;
    min-width: 60%;
  }

  .table {
    display: block;
    empty-cells: show;

    .row,
    .col {
      color: black;
      font-family: Roboto;
      font-size: 11px;
      width: 100%;
      display: flex;
    }

    thead {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      color: rgba(82, 82, 82, 0.6);
      background-color: rgba(196, 180, 180, 0.1);
      position: relative;
      display: block;
      width: 100%;

      .row {
        padding: 15px 0px;

        .col {
          border: none;
          color: rgba(82, 82, 82, 0.6);
          margin-left: 10px;

          &:first-child {
            color: black;
            letter-spacing: 1.71px;
          }
        }
      }
    }

    tbody {
      width: 100%;
      display: block;
      overflow: auto;
      height: 500px;
      position: relative;
      overflow-y: auto;
      margin-left: 10px;

      .col {
        vertical-align: bottom !important;
        width: inherit;
      }

      .row {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        align-items: center !important;
        vertical-align: bottom !important;
        text-align: center;

        &:first-child {
          border: none;
        }
      }
    }

    .mtt-btn {
      margin: 10px 0px !important;
      width: 120px !important;
      height: 40px !important;
      font-size: 11px !important;
    }
  }
}

.unregister-player-modal {
  @extend .custom-scroll;

  img {
    margin-bottom: 0 !important;
    margin-right: 5px;
  }

  .main-row {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
  }

  .filter-input {
    border-radius: 30px !important;
    float: right;
    display: block !important;
    margin-bottom: 30px !important;
  }

  .players {
    max-height: 500px;
    width: 100%;
    margin-top: 100px;
    text-align: center;
    position: relative;
    overflow-x: hidden;
  }

  .unregister-btn {
    margin: 0 30px !important;
    border-radius: 0 !important;
    width: fit-content !important;
    font-family: Roboto;
    font-size: 11px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    float: right;
    background-color: #5b38b9;
  }

  .name-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .id-text {
    opacity: 0.53;
    font-family: Roboto;
    font-size: 11px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #06050f;
  }

  .modal-content {
    padding: 50px !important;
  }

  .modal-title {
    align-self: flex-start;
  }

  .modal-text {
    align-self: flex-start;
  }
}

.receipt {
  color: #5b38b9;
  font-size: 40px;
}

//.customer-receipt {
//  .dashed-separator {
//    border-bottom: dashed 2px black !important;
//    display: block;
//    width: 100%;
//    margin: 10px 0 !important;
//  }
//
//  .bold {
//    font-size: 16px !important;
//    font-weight: bold;
//  }
//
//  .center {
//    width: auto !important;
//    text-align: center;
//    align-content: center;
//
//    p {
//      font-size: 13px;
//      margin: 0;
//    }
//  }
//}

.graySeparator {
  border-bottom: solid 1px rgba(192, 192, 192, 0.27) !important;
  display: block;
  width: 100%;
  margin: 20px 0 20px;
}

.greenDashedSeparator {
  border-bottom: dashed 2px $color-neon-green !important;
  display: block;
  width: 100%;
  margin: 20px 0 20px;
}

.confirm-pwd {
  top: 20%;
}

.profile-modal {
  .modal-content {
    padding-top: 0 !important;
  }

  .modal-width {
    width: 600px;
    min-width: 600px !important;
  }

  .input-with-icon {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    input {
      padding-right: 3.5rem !important;
    }
  }

  #labOldPassword {
    margin-top: 2rem;
  }

  #labNewPassword {
    margin-top: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 65%;
    align-items: center;

    label {
      width: 100%;
      text-align: left;
      color: $color-lighterGray;
    }

    .form-group {
      width: 100%;
    }

    .btn {
      font-weight: 500;
      width: 80% !important;
    }

    .btn-save-pwd {
      margin-top: 2.5rem;
    }

    .outline {
      background-color: $color-white;
      color: $color-violet;
      margin-top: 0.5rem !important;
    }

    .pwdError {
      color: red;
    }
  }
}

.register-modal {
  @extend .custom-scroll;

  .modal-width {
    min-width: 70% !important;
  }

  .modal-content {
    @extend .custom-scroll;

    background-color: #f8fafb;
    padding: 50px 50px !important;

    overflow-y: auto;
    overflow-x: hidden;

    .btn {
      float: right;
    }

    .required {
      color: red;
      margin: 5px;
    }
  }

  h3 {
    font-weight: bold !important;
  }

  h4 {
    color: gray;
  }

  form {
    margin: 0px;
  }

  .form-group {
    margin: 0px 10px;
  }

  .modal-title,
  .modal-text {
    width: 100%;
    text-align: left !important;
    padding: 0;
  }

  input.form-control {
    background-color: white;
    color: $color-black-main;
  }

  input.form-control:focus {
    background-color: white;
    color: $color-black-main;
  }
}

.table-legend-filled {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 3px;
  background-color: #5b38b9;
}

.table-legend-empty {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 3px;
  border: solid 1px #5b38b9;
}

input.form-control {
  height: 50px;
  font-size: 12px;
  background-color: $color-lightGray;
  border: none;
  color: white;
}

input.form-control:focus {
  height: 50px;
  font-size: 12px;
  background-color: $color-lightGray;
  border: none;
  color: white;
  box-shadow: inset 0 0 0 2px $primaryColor;
}

input.form-control.is-invalid {
  box-shadow: inset 0 0 0 2px #dc3545;
}

input.form-control:disabled {
  background-color: $color-lightGray;
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.form-control.light {
  background-color: white;
  color: $color-lightGray2;
  box-shadow: inset 0 0 0 2px $color-lightGray2;
}

.structure-table {
  background-color: black;
  margin-top: 50px;

  button {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .structure-table-header {
    div {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 0 3.5px;
    }
  }

  .structure-table-content {
    >div {
      vertical-align: middle;
      font-size: 12px;
      padding: 0 3.5px;
    }
  }

  .break {
    align-items: center;
  }

  .break-in {
    display: flex;
    flex-direction: row;

    &::before,
    &::after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid $primaryColor;
      margin: auto 10px auto 10px;
    }
  }

  .info {
    color: gray;
  }

  .error {
    color: red;
  }
}

.break-in {
  display: flex;
  flex-direction: row;

  &::before,
  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $primaryColor;
    margin: auto 1rem auto 1rem;
  }
}

.structure-level-options {
  display: inline-flex;
  align-items: center;

  .delete-break {
    color: #5b38b9;
    background: rgba(103, 77, 213, 0.21);
    letter-spacing: 1.05px;
  }
}

.delete-icon {
  color: $primaryColor;
  font-size: 25px;
}

a,
a:hover {
  color: inherit;
}

.custom-modal {
  .modal-content {
    width: auto !important;
    padding: 50px 20px;
    display: flex !important;

    align-items: center;
    color: black;
    //max-height: 90vh;

    img {
      margin-bottom: 30px;
    }

    .modal-close {
      display: block;
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
      position: absolute;
      top: 20px;
      right: 20px;

      &:hover {
        cursor: pointer;
      }

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background: $primaryColor;
        margin: 0 auto;
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    .modal-text {
      font-size: 15px;
      margin: 15px 0 30px 0;
    }

    label {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }

    .nationality-input {
      width: 100% !important;
    }

    .mtt-toggle,
    input[type="text"],
    input[type="date"] {
      margin: 5px 0;
      width: 300px;
      height: 50px;
      line-height: 50px;
      border: 1px solid #ebecf0;
      box-shadow: 0px 2px 3px 0px #d9dadd;
      font-weight: bold;
      border-radius: 7px;
      padding: 0 18px;
      font-size: 13px;
      background: white;
      color: black;

      &.dark {
        background: $color-lightGray;
        color: $color-white;
        border: none;
        box-shadow: none;

        &::placeholder {
          color: $color-gray3;
          font-weight: bold;
        }
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
        font-weight: bold;
      }

      &.placeholder {
        color: rgba(0, 0, 0, 0.25) !important;
        font-weight: bold;
      }
    }

    button {
      margin: 5px 0;
      font-size: 14px;
      letter-spacing: 1.1px;
    }
  }

  .dropdown-menu.show {
    height: 200px;
    overflow: auto;
    width: 300px;
  }

  .confirm-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 1rem !important;
      width: auto;

      &:first-of-type {
        margin-top: 1rem !important;
        margin-right: 1rem !important;
      }
    }
  }

  .modal-title {
    align-self: flex-start;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &.dark {
    .modal-content {
      color: $color-white;
      background-color: $color-gray2;
    }
  }
}

.balance-sheet-modal {
  .footer {
    width: 100%;

    .button {
      float: left;
      margin-top: 20px !important;
      margin-right: 10px !important;
      background-color: rgba(91, 56, 185, 0.13);
      color: #5b38b9;
      width: 150px !important;

      &:active {
        outline: none;
        border: none;
      }

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  input {
    align-self: center;
    float: left;
    width: 80px !important;
    height: 20px !important;
    font-family: Roboto;
    font-size: 11px;
    font-family: Roboto !important;
    font-size: 11px !important;
    font-weight: normal !important;
    text-align: center !important;
    border-width: 1px !important;
    border-radius: 5px !important;
  }

  .plus-button {
    float: right;
    vertical-align: middle !important;
    width: 20px !important;
    height: 20px !important;
    margin: 5px !important;
    border-radius: 100%;
    text-align: center !important;
    border: none;
    color: white;
    background-color: #5b38b9;
    font-size: 11px !important;
    letter-spacing: 0 !important;

    &:active {
      border: none;
    }
  }

  .modal-content {
    padding: 50px !important;
    display: inline-block !important;
  }

  .modal-title {
    align-self: flex-start;
  }

  .modal-text {
    align-self: flex-start;
  }

  .modal-width {
    min-width: auto !important;
  }

  .table {
    tr {
      color: black;
      font-family: Roboto;
      font-size: 11px;

      td {
        vertical-align: bottom !important;
      }
    }

    thead {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      color: rgba(82, 82, 82, 0.6);
      background-color: rgba(187, 187, 187, 0.1);

      tr {
        th {
          border: none;
          color: rgba(82, 82, 82, 0.6);

          &:first-child {
            color: black;
            letter-spacing: 1.71px;
          }
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          td {
            border: none;
          }
        }

        &:last-child {
          font-weight: bold;
        }
      }
    }
  }
}

.mtt-create-submenu {
  font-size: 17px;
  height: 50px;
  width: 100%;
  box-shadow: inset 0 0 0 0.5px #979797;
  border-radius: 4px;
  display: flex;
  align-items: center;

  >div {
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 50%;
    border: 1px solid white;
  }

  &.mtt-phase-done {
    background-color: #170a3a;
    box-shadow: none;

    >div {
      border: none;
      background: $color-neon-green;
    }

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: inherit;
      border-radius: 4px;
      background: $color-neon-green;
    }

    &.skipped {
      color: $color-lighterGray;

      >div {
        background: $color-lighterGray;
        color: $color-gray;
      }
    }
  }


}

.mtt-toggle {
  @extend input, .form-control;

  padding-top: 0;
  padding-bottom: 0;
  line-height: 50px;
  cursor: pointer;
  user-select: none;

  &::after {
    position: absolute;
    top: 35%;
    right: 1.5rem;
    border: solid currentColor;
    border-width: 0px 0.2em 0.2em 0;
    display: inline-block;
    padding: 0.3em;
    color: #5b38b9;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  span {
    font-size: 1rem;
    margin-right: 15px;
  }
}

.general-mtt-settings {
  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .title.with-switch {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .event-no {
    //  margin-left: $padding1;
    padding-left: 0;
  }

  .collapse {
    &.container {
      // margin-left: 0;
      // padding-left: 0;
    }
  }

  label {
    font-size: 14px;
  }

  .dropdown-menu.show {
    width: 100%;
    background-color: $color-lightGray;
    box-shadow: inset 0 0 0 2px $primaryColor;

    .dropdown-item:hover {
      background-color: transparent;
      cursor: pointer;
      user-select: none;
    }
  }

  .dropdown-menu.show {
    &.light {
      background-color: $color-white;
    }
  }

  .MuiSlider-valueLabel>span::after {
    content: "";
    position: absolute;
    top: 98%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $primaryColor transparent transparent transparent;
  }

  .mtt-settings-phases {
    >div {
      border-radius: 3px;
      background-color: #0f0f0f;
      width: 32%;
      height: fit-content;
    }

    .button {
      width: 93px;
      height: 26px;
      background-color: #5b38b9;
      font-size: 11px;
    }

    .single-phase {
      input {
        height: auto;
        font-size: 14px;
        padding: 0px;
        background: none;
        border-radius: 0px;
      }

      input.form-control:focus {
        box-shadow: none;
      }

      .editing-flight {
        box-shadow: inset 0 -2px 0 0 $primaryColor;
      }

      .flight-options {
        button {
          color: $primaryColor;
          border: none;
          border-radius: 0px;
        }
      }
    }
  }
}

.separator {
  border-bottom: solid 1px rgba(255, 255, 255, 0.17) !important;
  display: block;
  width: 100%;
  margin: 10px 0 0;
}

.toggleSwitch {
  margin-left: auto;
}

// custom toggle styling
.custom-toggle {
  height: 50px;
  border-radius: 25px;
  padding: 4px;
  background: #1e1f26;
  margin-left: 5px;

  .btn-check {
    display: none;
  }

  label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 100%;
    width: auto;
    margin: 0 !important;
    border-radius: 21px !important;
    box-shadow: none !important;
    transition: none;
    padding: 0 23px;

    &.active {
      background: $primaryColor !important;
    }

    &:active {
      background: $primaryColorHover !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    &:hover {
      height: 100%;
      width: auto;
      background: transparent;
      cursor: pointer;
    }
  }
}

.mtt-input-box {
  height: 48px;
  border-radius: 7px;
  width: 440px;
  overflow: hidden;

  input {
    height: 100%;
    background: #1e1f26;
    padding: 0 20px;
    font-size: 12px;
    outline: none;
    border: none;

    &:focus {
      background: #1e1f26;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  button {
    background: #1e1f26;
    border: none;
    border-radius: 0;

    &:hover {
      background: #1e1f26;
    }

    svg {
      path {
        fill: #5b38b9;
      }
    }
  }
}

.mtt-result-item {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  color: white;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    border-color: white;
  }

  &.show-more {
    color: $primaryColor;
    border-color: transparent;
    text-decoration: underline;

    &:hover {
      border-color: transparent;
      color: $primaryColorHover;
    }
  }

  &.active {
    border-color: $primaryColor;
  }
}

.circleIcon {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding: 15px;
}

.prefix .input-group-addon {
  border-right: 0;
  border-radius: 4px 0px 0px 4px;
  width: fit-content;
}

.prefix input {
  border-radius: 0px 4px 4px 0px;
}

.input-group-addon {
  background-color: $color-lighterGray;
  padding-top: 6px;
  padding-right: 3px;
  padding-bottom: 6px;
  padding-left: 5px;
  text-align: right;
  font-size: 12px;
  width: auto;
  display: inline-grid;
  align-items: center;
}

.clock-parent {
  font-size: 20px;

  line-height: 1;
  display: grid;
  height: 100%;
  min-height: 100%;
  max-height: 100%;

  @media (min-width: 3000px) {
    font-size: 45px;
  }
}


.full-size {
  @extend .mtt-clock;

  overflow: hidden;

  .clock-middle-panel {
    .clock-tournament-time {
      padding: 0px !important;

      .big {
        margin: auto !important;
      }

      .note {
        padding: 0px !important;
      }
    }
  }

  .clock-left-panel,
  .clock-right-panel {
    padding: 0.5em !important;

    .title {
      font-size: 2.6em !important;
    }

    .text {
      font-size: 2.4em !important;
    }
  }

  .clock-bottom {
    padding: 0.5em !important;
    height: 2.9em !important;

    .ticker {
      font-size: 2.6em !important;
      font-weight: 600;
    }
  }
}

.full-size-4k {
  font-size: 0;
  font-style: none;
  font-size: 2.4em !important;

  .clock-bottom {
    .ticker {
      font-size: 2.6em !important;
      font-weight: 600;
    }
  }
}

.clock {
  $margin: 0.5em;
  $title-height: 9.5em;
  display: flex;
  // background-image: url('http://localhost:4100/images/wild-west-product-image.jpg');
  //margin-top: 15px;

  &.bg {
    // background-image: url('http://localhost:4100/images/background.png');

    background-repeat: no-repeat;
    background-size: cover;

    *>div:not(.not-transparent) {
      background-color: transparent !important;
    }

    .note {
      background-color: transparent !important;
    }

    .action-panel {
      border: none !important;
    }
  }

  .mtt-clock {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .clock-left-panel {
      margin-right: $margin;
    }

    .clock-panel {
      display: inline-flex;
      width: 100%;
      height: 100%;
      margin-top: $margin;
      flex: 1;
    }

    .clock-left-panel,
    .clock-right-panel {
      width: 20%;

      display: flex;
      flex-direction: column;

      .item {
        flex: 1 1 auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .title {
        font-weight: 100;
        font-size: 1.5em;
      }

      .text {
        font-weight: bold;
        font-size: 1.3em;
        line-height: 1;
      }
    }

    .clock-middle-panel {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-right: $margin;
    }

    .clock-tournament-time {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 37%;
      position: relative;
      flex: 1;

      .small {
        font-size: 4em;
      }

      .big {
        font-size: 12em;
        font-weight: 500;
        padding-top: 0px;
      }
    }

    .notes {
      font-size: 2em;

      line-height: 1;

      position: absolute;
      bottom: 0;
      margin-bottom: 1rem;

      .note {
        border-radius: 0.4em;
      }
    }

    .clock-tournament-blinds {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: $margin;
      margin-top: 0.4em;
      height: 26%;

      .colorup {
        font-size: 2em;
        color: $color-neon-green;
        text-align: center;
      }

      .msg {
        text-align: center;
        white-space: nowrap;
        font-size: 3em;
        font-weight: 600;
      }

      .title {
        font-size: 3em;
        font-weight: 600;
      }
    }



    .clock-tournament-nextlevel {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: $margin;
      height: 23%;

      .title {
        font-size: 1.6em;
        font-weight: 100;
      }

      .text {
        font-size: 1.8em;
        font-weight: bold;
      }
    }

    .clock-bottom {
      .ticker {
        font-size: 2.2em;
      }

      padding: 0.5em !important;
      width: 100%;
      margin-top: $margin;

      @keyframes slidein {
        from {
          color: red;
        }

        to {
          color: black;
        }
      }

      .container {
        width: 100%;
        height: 18px;
        background-repeat: repeat;
        background-size: auto;
        animation: slidein 5s infinite;
        display: inline-block;
      }
    }

    .hidden-panel {
      visibility: hidden;
    }

    .clock-left-panel,
    .clock-right-panel,
    .clock-tournament-blinds,
    .clock-tournament-nextlevel,
    .clock-bottom,
    .clock-tournament-blinds,
    .clock-tournament-break,
    .clock-tournament-time,
    .clock-tournament-nextlevel {
      background-color: $color-black-accent;
      padding: 1em;
    }
  }

  .actions-panel {
    display: flex;
    flex-direction: column;

    .btn {
      //height: 3em;
      width: fit-content;
      padding-left: 1em;
      padding-right: 1em;

      &:first-child {
        margin-right: 0.7em;
      }

      &.circle {
        width: 3em;
        padding: 0;
      }

      &.disabled {
        background-color: $color-violet-05;
      }
    }

    .actions-panel-top {
      height: $title-height;
    }

    .actions-panel-bottom {
      margin-left: $margin;

      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .action-panel {
        flex: 1 1 auto;
        border: solid 1px $color-black-accent;
        white-space: nowrap;
        padding: 0.5em;
        margin-top: $margin;
      }
    }

    .action-panel {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      span {
        margin-bottom: 0.7em;
      }
    }
  }

  .notes {
    display: flex;
    flex-direction: column;

  }

  .note {
    color: $color-violet;
    background-color: $color-gray2;
    margin-top: 5px;
    padding: 0.2em 0.4em 0.2em 0.4em;
  }

  @media (max-width: 768px) {
    .note {
      padding: 0;
    }
  }

  &.director-clock {
    width: 100%;
    flex-direction: column;

    padding: 1rem;

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    *>.action-panel {
      width: auto;


      border: 1px solid $color-lightGray;
      padding: 1rem 1rem 1rem 1rem;
      margin-top: 2rem;
      margin-right: 2rem;
      align-items: center;
      text-align: center;

      .fit {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .title {
        margin-bottom: 1rem;
      }

      .btn {
        margin-right: 1rem;
      }
    }

    *>.action-panel-mobile {
      display: inline-flex;
      width: 100%;
      border: 1px solid $color-lightGray;
      padding: 1rem 1rem 1rem 1rem;
      margin-top: 2rem;
      text-align: left;

      .fit {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .title {
        margin-bottom: 1rem;
      }

      .btn {
        margin-right: 1rem;
      }

      .buttons {
        text-align: end;
        float: right;
        width: 100%;
      }

      p {
        margin-top: 1rem !important;
        margin-right: 1rem;
      }
    }

    .director-times {
      width: 100%;
      background-color: $color-black-accent2;
      padding: 2rem;
      opacity: 0.5;

      .col {
        padding-left: 0;
        padding-right: 0;

      }

      .row {
        margin-right: 0;
        margin-left: 0;
      }

      .row:not(:first-child) {
        .item {
          padding-top: 2rem;
        }
      }

      .col:not(:first-child) {
        padding-left: 2rem;
      }

      .col:not(:last-child) {
        padding-right: 2rem;
        border-right: 1px solid $color-lightGray;
      }

      .item {
        //background-color: blue;
      }


      .title {
        font-weight: 500;
        font-size: 1.2em;
      }

      .text {
        font-weight: 500;
        font-size: 1.8em;
      }

      .note {
        font-size: 1.5em;
        width: fit-content;
      }
    }

    .hide-payouts {
      display: inline-flex;
    }

    .clock-description {
      width: 100%;

      .input {
        width: 200px !important;
      }

      .row {}
    }
  }

}

.label {
  font-size: 0.5rem;
  background-color: #003118;
  margin-left: 7px;
  padding: 2px;
}

.violet-label {
  @extend .label;
  color: $color-violet;
}

.green-label {
  @extend .label;
  color: #00c162;
}

.gray-label {
  font-size: 0.7rem;
  background-color: $color-lightGray;
  margin-left: 7px;
  padding: 5px;
  color: white;
}

.white-label {
  @extend .label;
  color: $color-white;
}

.action-confirm-finish-modal {
  @extend .action-confirm-modal;

  .white {
    color: $color-white;
  }

  input {
    text-align: center;
  }
}

.btn-sit-in-mobile {
  width: max-content !important;
}

#waiting-list {
  .btn-sit-in {
    margin-right: 20px;
  }


  .tab-container {
    width: 100%;

    .btn {
      width: auto;
      flex: 0;
    }

    .btn-switch {
      width: 100%;
    }
  }

  .table {
    td {
      border-top: none;
      background-color: $color-black-accent;
      vertical-align: middle;
      padding: 5px;
    }

    .dark {
      background-color: $color-black-main;
    }

    tr {
      border: solid 15px $color-black-main;
      height: 90px;
    }

    h3 {
      margin-bottom: 0;
    }

    .btn {
      width: 130px;
    }
  }

  .bold {
    font-weight: 900;
  }

  .move-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;

    .btn-move {
      border: solid 2px $color-violet;
      padding: 0px;
      background-color: $color-black-main;
      color: $color-white;
      width: 3.2rem;
      margin-bottom: 5px;
      height: 1.5rem;
    }

    .btn-move.non-active {
      color: $color-lightGray;
      border-color: $color-violet-05;
      cursor: default;
    }

    .btn-move.down {
      margin-bottom: 0;
      margin-top: 3px;
    }
  }

  .seats {
    text-align: right;
    margin-right: 40px;
  }

  @media (max-width: 768px) {

    .table {
      tr {
        border: none
      }

      td {
        padding: 0;
        padding-left: 0.4rem;
      }
    }

    .seats {
      margin-right: 0.2rem;
    }

    .move-btns {
      .btn-move {
        width: 2.2rem;
      }
    }
  }
}

.printSeatTickedModal {
  .center {
    text-align: center;
    justify-content: center;
  }
}

#printSeatTickedModalButtons {
  .print-seat-ticket {
    display: none;
  }
}

#printSeatTickedModalButtons.confirm-btns {
  .btn {
    width: 120px !important;
  }
}

.table {
  .big-text {
    font-size: 1.6rem;
  }

  .small-text {
    font-size: 0.8rem;
  }
}

.no-wrap {
  white-space: nowrap;

  &.row {
    flex-wrap: nowrap;
  }
}

body>.print-seat-ticket {
  display: block;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  color: $color-violet;
}

.confirm-buttons {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  align-items: center;
  padding-left: $padding1;
  padding-right: $padding1;
  height: 100px;
  background-color: $color-blue;
  height: 80px;

  .btn {
    &.outline {
      background-color: $color-blue;
    }
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  &.content-start {
    justify-content: start;
  }
}

.filter {
  position: relative;

  input[type="text"] {
    width: 100%;
    border-radius: 25px;
    padding-right: 2rem;
  }

  svg {
    position: absolute;
    right: 30px;
    top: 15px;
    color: #5b38b9;
  }
}

#editMttSetting {
  @extend .custom-scroll;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);

  background-color: $color-black-main;

  .tournamentHeader {
    margin: $padding1 !important;
  }

  .general-mtt-settings,
  .flight-settings {
    @extend .custom-scroll;

    overflow: auto;
    background-color: $color-black-main;
    flex-grow: 1;
    margin-bottom: 60px;
    margin-left: 20px;
    height: 100% !important;
    min-width: 100%;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .header {
    display: flex;
    margin-bottom: 2rem;
    padding-left: $padding1;

    a {
      border: 0;
      background-color: $color-black-main;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }

    .pad-left {
      padding-left: 3rem;
    }

    .pad-right {
      padding-right: 3rem;
    }

    .active {
      white-space: nowrap;
      color: $color-white;
      border-bottom: solid 3px $color-violet;
    }

    .non-active {
      color: rgba(152, 148, 164, 0.58);
      border-bottom: solid 1px #2b2b2b;
      cursor: pointer;
    }

    .rest {
      width: 100%;
    }
  }
}

#players {
  .header {
    display: flex;
    margin-bottom: 1rem;

    h3 {
      display: inline;
    }

    .left {
      float: left;
      width: 100%;
    }

    .right {
      float: right;
    }
  }

  .searchByName {
    margin-bottom: 1rem;
  }

  .state-playing {
    color: $color-neon-green;
  }

  .state-waiting {
    color: $color-violet;
  }

  .right {
    text-align: right;
  }
}

.mtt-ddm {
  @extend input, .form-control;
  height: 100%;
  background-color: $color-black-main;
  float: right;

  .dropdown-toggle {
    border-radius: 0;
    background-color: $color-black-main;
    color: $color-white;
    border: none;
    margin-right: 10px;
    width: auto;
  }

  .dropdown-item {
    width: 150px;
    cursor: pointer;
  }

  .dropdown-menu {
    border-radius: 0;

    &.dark {
      background-color: $color-gray;
    }

    .btn {
      background-color: $color-gray !important;
    }
  }
}

#playerDetail {
  .label {
    color: $color-lighterGray;
    margin-bottom: 0;
  }

  .val {
    margin-bottom: 0.7rem;
  }

  .btn-action {
    width: min-content;
    text-align: center;
    margin-top: 3rem;
    margin-right: 3rem;
  }

  .action-buttons {
    display: inline-flex;
  }
}

.card {
  background-color: $color-black-accent;
  border-radius: 0;
  border: none;
  margin-bottom: 12px;
  margin-top: 0px;
  padding: 10px 19px 10px 19px;

  p {
    margin-bottom: 0px;
  }

  &.center {
    .row {
      align-items: baseline;
    }
  }
}

.log-card {
  @extend .card;

  .colX {
    width: 10rem;
    padding-right: 0.5rem;

    span {
      white-space: normal;
      overflow: hidden;
      display: inline-block;
      overflow-wrap: anywhere;
    }

    &.remXL {
      width: 20rem;
    }
  }

  .colLast {
    white-space: normal;
    width: 0;
    flex-grow: 2;
    flex-shrink: 0;
    display: flex;
  }

  &.title {
    font-weight: 600;
  }
}

.player-card {
  @extend .card;

  .col1 {
    width: 0;
    flex-grow: 2;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      flex-direction: row;
    }

    .lables {
      flex-direction: row;
    }
  }

  .col2 {
    flex-grow: 0;
  }

  .col3 {
    margin: auto;
    min-width: 150px;
  }

  .values {
    font-size: 1.5rem;
  }
}

#director-header {
  margin-top: 30px;

  .group {
    display: inline-flex;

    .profile-link {
      background-color: transparent;
      cursor: pointer;
      text-decoration: underline;
      text-align: left;
      padding: 0;
      height: auto;
      bottom: 0;
      margin-left: 3px;

      &:hover,
      &:active,
      &:focus,
      &.visited,
      &.disabled {
        background-color: transparent !important;
        text-decoration: underline;
        height: auto;
        width: auto;
      }
    }
  }

  h2 {
    overflow-wrap: break-word;
  }

  span {
    color: $color-lightGray2;
  }

  .btn {
    color: $color-violet;
    font-weight: 600;
    width: auto !important;
  }
}

.error-msg {
  width: 100%;
  margin-left: 30px;
}

#tournamentList {
  padding-top: 30px;

  .filter {
    padding-top: 20px;
  }
}

.tournament-icon {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  margin-right: 20px;
  border-radius: 50%;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &.tournament-clock-header {
    position: absolute;
    right: 4%;
    height: 80%;
    width: auto;
    padding: 1.5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.full-height {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#mttDirectorPage {
  height: 100%;
  padding: 0;

  .full-screen {
    height: 100%;
    width: 100%;
    margin-left: 0;
  }

  .content {
    overflow: auto;
    max-height: 100%;
    margin-left: 0;
    padding-left: $padding1;
    padding-right: 15px;
  }
}

.mtt-history-detail {
  .modal-small-width {
    max-width: 550px !important;
  }

  div {
    width: 100%;
  }

  .tournament-tab {
    border-bottom: none;
  }

  .btn-switch {
    margin-bottom: 1rem;
    display: inline-block;

    .btn {
      background: none;
      padding-right: 1rem;
      padding-left: 1rem;
      height: 2.5rem !important;
      border-bottom: 1px solid $color-gray;
      width: auto !important;
    }

    .active {
      border-bottom: 3px solid #674dd5 !important;
      color: $color-white;
      background-color: $color-gray2 !important;
    }
  }

  .finish-info {
    display: flex;
    flex-direction: column;
  }

  .table {
    margin-top: 1rem;
  }

  .title {
    font: $font-small;
  }

  .value {
    font: $font-small-bold;
  }
}

#tournamentList {
  overflow: hidden;
}

#editStructure {
  @extend .custom-scroll;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .scrl {
    overflow: auto;
    overflow-x: hidden;
  }

  .tournamentHeader {
    margin: $padding1 !important;
  }

  .structure-title {
    padding-left: $padding1;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .structure {
    @extend .custom-scroll;
    overflow: hidden;
    //max-width: 1100px;
    width: 100%;
    padding-left: $padding1;
    flex-grow: 1;
    height: fit-content !important;
    padding-bottom: 70px;

    .structure-table {
      @extend .custom-scroll;
      overflow-y: auto;
      max-height: 100%;
      height: 100%;
      float: left;
    }
  }
}

#templates {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .body {
    height: 100% !important;
    padding-bottom: 80px;
    /* Height of the footer */
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    /* Height of the footer */
    background-color: $color-blue;
  }
}

#wizard {
  @extend .custom-scroll;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .body {
    @extend .custom-scroll;
    padding-bottom: 70px;
    /* Height of the footer */
  }

  .confirm-buttons {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    /* Height of the footer */
    background-color: $color-blue;
  }

  .navigation {
    display: flex;
    flex-wrap: nowrap;
    height: 5rem;
    background-color: $color-blue;

    .menu-button {
      font-size: 1.1rem;
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      background-color: $color-blue;
      flex-grow: 1;
      justify-content: center;
      border-radius: 0;

      &:hover,
      &:active,
      &:focus,
      &.visited,
      &.disabled {
        opacity: 1;
      }

      &.disabled {
        cursor: default;
      }

      span {
        white-space: nowrap;
      }

      .icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
        min-height: 25px;
        display: flex;
        border-radius: 50%;
        background-color: $color-violet2;
        margin-left: 0 !important;
      }

      &.step-done {
        cursor: pointer;

        .icon {
          background: $color-neon-green;
        }

        border-bottom: solid 3px $color-neon-green;
      }
    }
  }
}

#create-tournament {
  @extend .custom-scroll;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .body {
    @extend .custom-scroll;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    float: left;
    padding-left: $padding1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .confirm-buttons {
    max-width: 100%;
  }

  .mtt-create-steps {
    display: flex;
    flex-wrap: nowrap;
    height: 5rem;

    .create-step-button {
      font-size: 1.1rem;
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      background-color: $color-blue;
      flex-grow: 1;
      justify-content: center;
      border-radius: 0;

      &:hover,
      &:active,
      &:focus,
      &.visited,
      &.disabled {
        opacity: 1;
      }

      &.disabled {
        cursor: default;
      }

      span {
        white-space: nowrap;
      }

      .icon {
        width: 25px;
        min-width: 25px;
        height: 25px;
        min-height: 25px;
        display: flex;
        border-radius: 50%;
        background-color: $color-violet2;
        margin-left: 0 !important;
      }

      &.mtt-phase-done {
        cursor: pointer;

        .icon {
          background: $color-neon-green;
        }

        border-bottom: solid 3px $color-neon-green;
      }
    }
  }
}

.reg-player-btn-switch {
  width: auto;
  float: left;
  border-bottom: 1px solid $color-lightGray4 !important;
  margin-bottom: 2rem;
  width: 100%;

  .btn-switch {
    width: auto;
  }

  .btn {
    margin-bottom: 0 !important;
    font-weight: 600;
    font-size: 1rem !important;
  }

  .tab-button {
    white-space: nowrap;
    margin-right: 2rem !important;
    margin-bottom: 0;
    background-color: red;
  }
}

#addNewCustomer {
  width: 100%;

  .btt-link {
    color: $color-violet;
    font-weight: 700;
    bottom: auto;
  }

  .is-invalid {
    box-shadow: inset 0 0 0 1px #dc3545 !important;
  }

  *>input,
  .dropdown {
    margin-right: 15px;
  }
}

.reseat-history {
  margin-left: 1rem;

  h3 {
    color: gray;
    margin-top: 1.5em;
  }

  .title {
    font-size: small;
    color: gray;
  }

  .text {
    font-size: x-large;
  }

  .history-row {
    background-color: $color-black-accent;
    padding: 0.4em;
    margin-bottom: 0.6em;
  }

  .action {
    width: fit-content;
    padding: 1em;
  }
}

.reseatForNewDay-table {
  margin-top: 20px;

  tr {
    font-family: Roboto;
    font-size: 11px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;

    td {
      vertical-align: middle !important;
    }
  }

  thead {
    tr {
      th {
        border: none;
        vertical-align: middle;
        font-family: Roboto;
        font-size: 9px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.71px;
        background-color: rgba(249, 251, 252, 0.06);
        color: rgba(255, 255, 255, 0.58) !important;

        &:first-child {
          font-size: 10px;
          letter-spacing: 1.71px;
        }

        .sort-icon {
          color: $color-violet;
          font-size: 0.9rem;
          cursor: pointer;
        }
      }
    }
  }

  tbody {
    td {
      border-top: 1px solid rgba(255, 255, 255, 0.17) !important;
    }

    tr {
      &:first-child {
        td {
          border: none !important;
        }
      }
    }
  }
}

#transferPlayers {
  @extend .custom-scroll;
  margin-top: 0.6rem;
  width: 100%;

  .seach-msg {
    width: 100%;
    height: 150px;
    display: flex;
    color: $color-violet;
    background-color: $color-white;
    font-weight: 700;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
  }

  .players {
    width: 100%;

    .scroll {
      @extend .custom-scroll;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 150px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .player {
      width: 100%;

      font-family: Roboto;
      font-weight: 500;
      font-size: 0.8rem;
      padding: 0.4rem;
      border-radius: 3px;
      cursor: pointer;

      &:hover:not(.selected) {
        background-color: $color-violet-01;
      }

      &.selected {
        background-color: $color-violet;
        color: $color-white;
      }

      &.header {
        background-color: $color-lightGray4;
        color: $color-lightGray;
        font-weight: 800;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;
      }

      .col1 {
        width: 0;
        flex-grow: 2;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
      }

      .col2 {
        width: 120px;
      }

      .col3 {
        width: 120px;
      }

      .col4 {
        width: 100px;
      }
    }
  }

  .elipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
  }

  .random-input {
    position: relative;
    float: right;
    margin: 1rem;
    margin-left: 00.3em;
    width: 10%;
  }

  .random-text {
    position: relative;
    float: right;
    margin: 1rem;
    margin-right: 0;
    align-self: center;
    color: $color-lightGray2;
    font-size: 0.8rem;
  }

  .search-player {
    position: relative;
    width: 250px;
    align-items: center;
    margin: 1rem;

    input[type="text"] {
      width: 100%;
      border-radius: 25px;
      padding-right: 2rem;
    }

    svg {
      position: absolute;
      right: 1rem;
      height: 50%;
      top: 25%;
      color: $color-violet;
    }
  }

  .search-content {
    @extend .search-player;
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 10px !important;
  }

  .playerCheck {
    margin: 0px;
    position: relative
  }

  .giveup-btn {
    width: fit-content;

    &:hover,
    &:active,
    &:focus,
    &.visited {
      background-color: $color-violet;
      color: black;
    }
  }
}

#addExistingCustomer {
  @extend .custom-scroll;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;

  .row {
    margin: 0px !important;
  }

  .seach-msg {
    width: 100%;
    height: 150px;
    display: flex;
    color: $color-violet;
    background-color: $color-white;
    font-weight: 700;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
  }

  .players {
    width: 100%;

    .scroll {
      @extend .custom-scroll;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 150px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .player {
      width: 100%;

      font-family: Roboto;
      font-weight: 500;
      font-size: 0.8rem;
      padding: 0.4rem;
      border-radius: 3px;
      cursor: pointer;

      &:hover:not(.selected) {
        background-color: $color-violet-01;
      }

      &.selected {
        background-color: $color-violet;
        color: $color-white;
      }

      &.header {
        background-color: $color-lightGray4;
        color: $color-lightGray;
        font-weight: 800;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;
      }

      .col1 {
        width: 0;
        flex-grow: 2;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
      }

      .col5 {
        width: 0;
        flex-grow: 2;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        color: red;

      }

      .col2 {
        width: 120px;
      }

      .col3 {
        width: 120px;
      }

      .col4 {
        width: 100px;
      }
    }
  }

  .elipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
  }

  .search-player {
    position: sticky;
    top: 100px;
    right: 50px;
    width: 280px;
    flex-wrap: nowrap;
    align-items: center;

    input[type="text"] {
      width: 100%;
      border-radius: 25px;
      padding-right: 2rem;
    }

    svg {
      position: absolute;
      right: 1rem;
      height: 50%;
      top: 25%;
      color: $color-violet;
    }
  }

  .search-content {
    @extend .search-player;
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 10px !important;
  }
}

.addOnDiv {
  display: flex;
  width: 100%;
  align-items: center;

  .form-label {
    margin-left: 10px !important;

    &.disabled {
      color: #6c757d;
    }
  }
}

.input-with-icon {
  margin-bottom: 0.4rem;

  svg {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 100%;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: $color-violet-07;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    pointer-events: all;
  }

  input {
    padding-left: 2.375rem;
    z-index: -1;
  }

  &.right {
    direction: rtl;

    svg {
      margin-left: 0rem;
      margin-right: 1rem;
    }

    input {
      direction: ltr;
      padding-right: 2.375rem;
      padding-left: 0.75rem;
    }
  }
}

.paging-buttons {
  justify-content: center;

  .btn {
    &:not(.selected) {
      background-color: $color-black-main;
      color: $color-lightGray2;
    }

    font-weight: 500;
    font-size: 1.3rem;
    min-width: 2.5rem;
    width: auto;
    height: 2.5rem;
    border-radius: 0;
    margin-left: 0.5rem;
    padding: 0.1rem;
  }
}

.to-flight-dd {
  border: 2px solid $color-violet;
  border-radius: 5px;
  width: fit-content;
  min-width: 120px;
}

.multiselect {
  .searchWrapper {
    border: 2px solid $color-violet;
    width: fit-content;
    max-width: 200px;
    min-height: 55px;
    background-color: #343642;

    .chip {
      background-color: $color-violet;
    }
  }

  .optionListContainer {
    width: fit-content;

    .optionContainer {
      color: black;

      .highlightOption {
        background-color: $color-violet;
      }

      li {
        padding: 10px 30px;
      }
    }
  }
}

.multiselect-dropdown {
  padding: 10px;
  padding-left: 30px;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px $primaryColor;
  height: fit-content;
  position: absolute;
  z-index: 1000;
  background-color: black;

  .scroll {
    color: $color-violet;
    float: right;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    margin-right: 10px;
  }

  .header {
    color: $color-violet;
    align-self: center;
    margin-left: 5px;
    font-size: 13px;
  }

  .item {
    margin-left: 5px;
    margin-top: 5px;
    align-items: center;
  }

  .sub-item {
    margin-left: 10px;
    font-weight: 300;
    cursor: pointer;
  }
}

.multiselect-dropdown2 {
  @extend .multiselect-dropdown;
  right: 10px;
  top: auto;
  width: fit-content;
}

.rotating-tables {
  display: flex;
  flex-direction: column;

  .header {
    background-color: greenyellow;
    height: 100px;
    margin: 0;
    margin-top: 20px;

    .tournamentName {
      font-size: 3rem;
      text-align: center !important;
    }
  }

  .table {
    background-color: $color-black-accent2;
    padding: $padding1 3rem 5rem 3rem;
    margin-top: $padding1;
    flex-grow: 1;
  }

  .table-name {
    font-weight: 800;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }

  .players {
    min-height: 80%;
    height: 200px;
    justify-content: left;
    width: 100%;
    margin-top: 3.5rem;

    .player {
      display: flex;
      flex-direction: row;
      min-height: 6rem;

      &.hide {
        display: none !important;
      }

      .seat {
        width: fit-content;
        text-align: center;
        display: flex;
        flex-direction: column;

        .seat-num {
          font-size: 1.9rem;
          font-weight: 600;
          line-height: 1;
        }

        .seat-txt {
          font-size: 1rem;
        }
      }

      .name {
        flex-grow: 1;
        font-size: 2.8rem;
        margin-left: 1rem;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;
      }
    }
  }

  .paging-buttons {
    margin-top: 0.1rem;

    .btn:disabled {
      opacity: 1;
    }
  }

  .btn-dark {
    background: none;
  }
}

.clock-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 9.5em;
  ;
  position: relative;
  background-color: $color-black-accent;

  .mainTitle {
    font-size: 3.6em;
  }

  .subtitle {
    font-size: 2.6em;
  }
}

.closed-table-detail-modal {
  @extend .table-settings-modal;
  @extend .custom-scroll;

  .players {
    width: 100%;
    min-width: 400px;
  }
}

.hidden {
  display: none;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
}

.redrawRemainingTables {
  .line {
    margin-bottom: 0 !important;
  }
}

.print {
  width: 250px;

  margin: 0 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1;
  font-size: 50px;

  .bold {
    color: blue;
    font-weight: 900;
    font-size: 90px;
  }

  p {
    margin: 10px;
    font-size: 90px;
  }

  .big {
    //font-size: 200px !important;
    //line-height: 2;
  }
}

.plusminus {
  .txt {
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    text-align: center !important;
    width: 80px !important;
  }

  .btn {
    margin: 0 !important;
    width: auto;
    padding: 1rem;
    font-weight: bold;
  }
}

.pointer {
  cursor: pointer;
}

.grid-legend-item {
  height: 3rem;
  align-items: center;
  vertical-align: middle;
  display: flex;
}

.no-margins {
  margin-right: 0 !important;
}

.no-paddings {
  padding: 0;
}

.three-dots {
  overflow: hidden;
  overflow: hidden;
  word-break: break-word;
  padding-right: 5px !important;
}


@media (max-width: 768px) {
  #mttDirectorPage {
    .content {
      overflow: unset !important;
    }
  }

  #editPayouts {
    .row {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: inherit !important;
      margin-right: inherit !important;
    }
  }

  .lightBox {
    background-color: $color-black-accent;
    font-size: smaller;
  }

  .table-seat-dot {
    width: 8px;
    height: 8px;
  }

  .table-list-modal {
    .modal-small-width {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .modal-small-width {
    width: 100% !important;
    max-width: 100% !important;
  }

  .choose-player-modal .modal-content {
    padding-right: 0 !important;
  }

  .player-actions-modal .modal-small-width,
  .seat-open-modal .modal-width {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
  }

  .player-actions-modal .chipcount {
    width: 100% !important;
  }

  .card,
  .player-card,
  .log-card {
    padding: 0.5rem;
  }

  .bg-gray {
    background: #161616 !important;
  }

  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: rgb(21, 28, 43);
  }

  .action-confirm-modal {
    .modal-dialog {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      background-color: rgb(21, 28, 43);
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .modal-content {
      border: 0;

      h2 {
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  .profile-modal {
    .modal-dialog {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      background-color: rgb(21, 28, 43);

    }

    .content {
      padding: 0 !important;
      width: 100% !important;
    }

    .modal-content {
      border: 0;

      h2 {
        font-size: 2rem;
        text-align: center;
      }
    }

    .modal-width {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .action-confirm-modal-mobile {
    .modal-dialog {
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      background-color: rgb(21, 28, 43);
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .modal-content {
      border: 0;

      h2 {
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  .modal-content {
    border: 0;
  }

  .confirm-buttons {
    z-index: 9999999;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .reseat-player-seats .modal-small-width {
    width: 100% !important;
    max-width: 100% !important;
  }

  .table-settings-modal .modal-content,
  .swap-seats-modal .modal-content,
  .check-chip-counts-modal .modal-content,
  .choose-player-modal .modal-content,
  .seat-open-modal .modal-content,
  .reseat-player-tables .modal-content,
  .reseat-all-players .modal-content {
    padding: 20px !important;
    float: unset;
  }

  .closed-table-detail-modal .modal-content {
    padding: 0 20px 0 20px !important;
    float: unset;
  }

  .custom-modal .modal-content .modal-close {
    z-index: 9999;
  }

  .navbar-text {
    font-size: 0.7em;
  }

  .dropdown-item {
    background-color: #5B38B9;
    border: 0;
    border-bottom: 1px solid #2d2d2d;
    color: white;
    font-size: 0.6em;
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 5% 0;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background-color: #5B38B9;
      color: white;
    }
  }

  .dropdown-menu {
    background-color: transparent;
    width: 100px;
  }

  #bottom-navbar-mobile {
    background: #000;
  }

  .variant-picker-btn-selected {
    display: flex;
  }
}

.casino-settings {

  padding-top: 2rem;

  *>h2 {
    margin-top: 2rem;
  }

  *>.row {
    margin-right: 0;
    margin-left: 0;
  }

  width: 100%;

  .casino-content {
    width: 100%;
  }

  .item {
    height: 4rem;
    align-items: center;
  }

  .item-value {
    max-width: 250px;
  }
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: gray;
  justify-content: space-between;
  padding: 10px;
}

.bottom-bar .nav-link {
  color: gray;
}

.bottom-bar .nav-link.active {
  color: #5B38B9;
}

.dropdown-up .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: .5rem;
}

.dropdown-up .dropdown-toggle::after {
  display: none;
}

.dropdown .dropdown-menu {
  background-color: #5B38B9;
  color: white;
}

.dropdown .dropdown-item {
  color: white;
}

.dropdown .dropdown-toggle {
  color: gray;
}

.dropdown .dropdown-toggle.active {
  color: #5B38B9;
}

.dropdown-basic {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.show>.btn-success.dropdown-toggle,
.show>.btn-success.dropdown-toggle:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .bottom-bar .nav-link {
    padding: .25rem .5rem;
    font-size: .875rem;
  }

  .selected {
    color: white
  }
}

.bottom-bar .dropdown-toggle,
.navbar-text {
  color: gray;
  font-size: 0.8rem;
  background-color: transparent;
  border-color: transparent;
}

.bottom-bar .dropdown-toggle:hover,
.bottom-bar .dropdown-toggle:focus {
  color: #5B38B9;
  background-color: transparent;
  border-color: transparent;
}

.bottom-bar .dropdown-toggle::after {
  display: none;
}

.bottom-bar .dropdown-toggle.active {
  color: purple;
}

.dropdown-toggle {
  padding: 0;
}

.bottom-bar .custom-nav-link .nav-icon {
  font-size: 1.5em;
  text-align: center;
  height: 27px;
}

@media (max-width: 768px) {
  .bottom-bar .custom-nav-link .nav-icon {
    text-align: center;
    display: grid;
    margin-bottom: 0;
    height: 1.5rem;
  }

  .svg {
    grid-column: 1;
    grid-row: 1;
  }

  .number {
    grid-column: 2;
    grid-row: 1;
    background-color: $color-violet;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    text-align: center;
    justify-content: center;
    font-size: 0.7rem !important;
    color: $color-white !important;
  }
}

.bottom-bar .custom-nav-link div {
  font-size: 0.8rem;
  text-align: center;
}

.bottom-bar .custom-nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}



#dropdown-item {
  background-color: #5B38B9;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom: 1px solid #2d2d2d;
  border-left-width: 0;
  color: white;
  font-size: 0.6em;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 5% 0;
  width: 100%;
}