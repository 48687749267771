$color-gray: #2f2f2f;

.user-management {
  @extend .custom-scroll;
  padding-top: 30px;
}

.players-management {
  @extend .custom-scroll;

  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  .row {
    padding-left: 0px;
    margin: 5px;
  }

  .header {
    margin-top: 1rem;
    flex: 0 0 auto;
  }

  .players-rows {
    @extend .custom-scroll;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 10px;
    flex-direction: column;
    .row {
      padding: 10px;
      margin: 10px;
    }
  }
}

.add-account-btn {
  width: auto !important;
  height: auto !important;
  font-size: 13px;
  margin-bottom: 10px;
}

.staff-list {
  //height: 400px !important;
  //max-height: 400px !important;
  position: relative;
  overflow-x: hidden;
}

.add-account {
  .modal-width {
    width: 380px;
    max-width: 380px !important;
  }
  input {
    color: black !important;
    background-color: white !important;
    border: lightgray 1px solid !important;
    box-shadow: 0 0 1px 1px lightgray !important;
  }
}

.staff-card {
  height: auto;
  width: auto;
  width: 70%;
  margin-top: 20px !important;

  .btn {
    font-size: 13px;
  }
  .row {
    margin: 0;
    padding: 0;
    .col {
      align-self: start;
    }
  }

  .identifier {
    font-size: 17px;
  }
  .type {
    font-size: 11px;
    color: gray;
  }
  .actions {
    float: right;
  }
  .trash-icon {
    font-size: 23px;
    margin-left: 10px;
  }

  input {
    background-color: $color-gray !important;
    opacity: 100;
    border-radius: 4px;
    vertical-align: top !important;
  }

  .mtt-ddm {
    background-color: $color-gray !important;
    opacity: 100;
    border-radius: 4px;
    width: 200px;
  }
  .dropdown-toggle {
    background-color: $color-gray !important;
    opacity: 100;
    border-radius: 4px !important;
  }

  .confirm-button {
    float: right;
    margin-left: 8px;
    width: auto;
    height: auto;
  }
}

.reset-password {
  text-align: center;
  .modal-content {
    padding-top: 0px !important;
    padding-bottom: 20px !important;
    .confirmation-btn {
      margin-top: 30px;
      width: fit-content;
      height: fit-content;
    }
  }

  .modal-width {
    width: 300px;
    max-width: 300px !important;
  }
  .main-text {
    margin-top: 0px;
    font-size: 22px;
    font-weight: 500;
  }
  .small-text {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
  }
  .password {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 4px;
  }
}
