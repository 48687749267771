$backgroundColor: #151c2b;

.modal-body,
.modal-footer,
.modal-header {
  background-color: $backgroundColor;
  color: white;
  font-family: "Roboto";
}

/*remove this and bootstrap components*/
.bg-light,
.navbar-light,
.navbar-brand,
.nav-link {
  color: white !important;
  font-family: "Roboto";
  margin: 0 0 0 20px;
}

.small-modal {
}

.light-modal-body {
  text-align: center;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #f8fafb;
  color: #3f3f3f;
  div {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    width: 80%;

    label {
      float: left;
    }
    input {
      margin-bottom: 2px;
      padding-left: 15px;
      height: 50px;
      border-radius: 7px;
      box-shadow: 0 2px 3px 0 #d9dadd;
      border: solid 1px #ebecf0;
      background-color: #ffffff;
    }
    button {
      margin-top: 15px;
    }

    input:focus {
      outline: none;
    }
  }
}
