
.variant-picker{
  width: 100%;
  padding-top: 15px;
  background-color: #161616;
  margin-bottom: 30px;

  &.single {
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .variants {
    background: #170a3a;
  }

  > * {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .tournamentHeader {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .variant-picker {
    width: 100%;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;

    &.single {
      padding-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .variants {
      background: transparent;
    }

    > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .tournamentHeader {
      padding-left: 0;
    }
  }
}

.tournamentHeader {
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  background-color: #161616;
  padding-left: 15px;
  height: 4rem;

  .gray {
    color: gray;
  }
  .status-box {
    margin-left: 2rem;
    padding: 4px;
    font-size: 0.6rem;
    border-radius: 2px;
    letter-spacing: 1.6px;

    &.green {
      color: #00C162;
      background-color: rgba($color:  #00C162, $alpha: 0.3);
    }

    &.red {
      color: red;
      background-color: rgba($color:  red, $alpha: 0.3);
    }

    &.blue {
      color: blue;
      background-color: rgba($color:  blue, $alpha: 0.3);
    }
  }
}

.variant-picker-col{
  display: block;
  width: max-content;
  margin: 0;
  padding: 0;
}

.variant-picker-btn {
  display: block;
  border: none;
  background-color: transparent;
  line-height: 56px;
  color: white;
  font-size: 15px;
  width: max-content;
  cursor: pointer;
}

.variant-picker-icon {
  margin: 7px;
}

.variant-picker-label {
  margin-right: 7px;
}

.variant-picker-btn-selected {
  background-color: #0f4346;
  line-height: 53px;
  color: white;
  font-size: 15px;
  border: 0px;
  outline: none;
  border-bottom: 3px solid #00c162;
  width: max-content;
}
button:focus {
  outline: none;
}

.variant-picker-btn:hover{
  background-color: #141b3d;
  transition-duration: 0.4s;
}

@media only screen and (max-width: 768px) {
  .variant-picker-col{
    display: table-cell;
    width: max-content;
    margin: 0;
    padding: 0;
  }
}
