$primaryColor: #5b38b9;
$primaryColorHover: #4a389b;

$secondaryColor: #00c162;
$secondaryColorHover: #00a452;

$backgroundColor: #151c2b;

$imageBackgroundColor: #0a1524;
$imageBackgroundColorHover: #0a2837;

.btn {
  color: white;

  &.fit,
  .fit:hover {
    width: auto !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &.img-left {
    span {
      margin-left: 0.5rem;
    }
  }

  &.img-right {
    span {
      margin-right: 0.5rem;
    }
  }
}

.btn:hover {
  color: white;
}

.button {
  width: 100%;
  height: 49px;
  border: none;
  border-radius: 24.5px;
  font-family: "Roboto";
}

.button.circle {
  width: 49px;
}
.button.icon {
  width: 49px;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &.visited,
  &.disabled {
    background-color: transparent;
  }

  &.red {
    color: red;
  }

  &.green {
    color: $secondaryColor
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $primaryColor;
  border-color: $primaryColor;
  cursor: default !important;

  &.disabled-dark {
    background-color: $backgroundColor;
  }

  &.disabled-dark:active {
    background-color:  $primaryColor !important; // $backgroundColor !important;
  }
  &.disabled-dark:not(.active):active {
    background-color: $backgroundColor !important;
  }
}


.btn-secondary {
  @extend .button;
  background-color: $secondaryColor;

  &:hover,
  &:active,
  &:focus,
  &.visited,
  &.disabled {
    background-color: $secondaryColor;
  }
}
.black.btn-primary {
  @extend .button;
  background-color: $backgroundColor;
  border: 2px solid $primaryColor;
}

.btn-primary {
  @extend .button;
  background-color: $primaryColor;
}

.btn-primary:hover {
  @extend .button;
  background-color: $primaryColorHover;
}

.btn-outline-primary {
  @extend .button;
  letter-spacing: 1.05px;
  box-shadow: 0px 0px 0px 2px $primaryColor inset !important;
  color: white;

  &:hover {
    background-color: $primaryColorHover;
  }

  &:active,
  &:focus,
  &:visited {
    box-shadow: 0px 0px 0px 2px $primaryColor inset !important;
  }
}

.ui.button {
  background-color: $imageBackgroundColor;
}

.ui.button:hover {
  background-color: $imageBackgroundColorHover;
}

.btt-link {
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  padding: 0;
  height: auto;
  position: absolute;
  bottom: 0;

  &:hover,
  &:active,
  &:focus,
  &.visited,
  &.disabled {
    background-color: transparent !important;
    text-decoration: underline;
    height: auto;
    width: auto;
  }
}
