$backgroundColor: #170a3a;
$color: #5B38B9;

.list {
  vertical-align: middle;
  font-size: small;
  margin-top: 20px;
  > .list-row {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 2px solid #1A1A23;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      width: 130px;
    }

  }
  > .list-header {
    color: #98979C;
    text-transform: uppercase;
    padding: 5px;
  }
}