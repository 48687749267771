$color-violet: #5b38b9;
$padding1: 15px;

.custom-scroll {
  ::-webkit-scrollbar-thumb {
    background: #5b38b9;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }
}

#table-list {

  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  margin-left: calc(#{$padding1} * -1);
  margin-right: calc(#{$padding1} * -1);

  .variant-picker {
    margin: $padding1;
    width: inherit;
  }

  @media (max-width: 767px) {
    .variant-picker {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: $padding1;
      margin-right: $padding1;
      width: inherit;
    }
  }

  .table-list-content-parent {
    height: 100%;
  }

  .table-list-content {
    @extend .custom-scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    width: inherit;
    overflow-x: hidden;
    padding-right: $padding1;
    padding-left: $padding1;


    .tables-rows {
      @extend .custom-scroll;

      height: 100%;
      align-content: flex-start;
      overflow: auto;
      margin-left: 0;
      margin-bottom: 5rem;
    }

    .confirm-buttons {
      width: inherit;
      margin-left: calc(#{$padding1} * -1);
      margin-right: calc(#{$padding1} * -1);
    }
  }
}


.list-card-main-title {
  background-color: #161616;
  margin-bottom: 30px;
  line-height: 70px;
}

.table-detail-main-title {
  background-color: #161616;
  line-height: 30px;
  margin: 0;
}

.list-card-main-title-text {
  font-size: 24px;
  float: left;
  margin-left: 10px;
}

.list-modal-table-edit-text {
  font-size: 12px;
  float: left;
  margin-left: 10px;
}

.list-card-main-title-icon {
  width: 38px;
  float: left;
  margin-left: 20px;
  padding-top: 17px;
}

.list-card-hand-icon {
  margin-right: 15px;
  width: 26px;
}

.list-card-lock-icon {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 0.5rem;
  //background-color: red;
  padding: 0.3rem;
  border: solid 2px $color-violet;

  &.locked {
    background-color: $color-violet;
  }
}

.list-card-lock-state {
  font-size: 9px;
  letter-spacing: 0.32px;
  text-align: center;
}

.list-card-message {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 13px;
  text-align: center;
  margin-top: 15px;
}

.list-card-hr {
  border: 1px solid #161616;
}

.list-card-id-text {
  font-size: 17px;
  letter-spacing: 0.03px;
  text-align: left;
  font-weight: bold;
  float: right;
}

.list-card-closed-title {
  font-size: 16px;
  letter-spacing: 1.69px;
  text-align: center;
}

.list-card-closedAt-title {
  font-size: 16px;
  letter-spacing: 1.69px;
  opacity: 0.32;
  text-align: center;
}

.list-card-dots {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100%;
  white-space: normal;
}

.list-card-opened-title {
  font-size: 14px;
  letter-spacing: 0.46px;
  text-align: left;
}

.list-card-title-green {
  color: #00C162;
}

.list-card-title-purple {
  color: #5B38B9;
}

.list-card-title-red {
  color: #CF0202;
}

.marg-min30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.list-card-players-text {
  font-size: 1rem;
  letter-spacing: 0.62px;
  text-align: left;
}

.list-card {
  width: 100% !important;
  background: #161616;
}

.list-card-closed {
  width: 100% !important;
  height: 100%;
  background: transparent;
  border: 1px solid $color-violet;
}

.list-card a {
  color: inherit;
}

.list-card a:hover {
  text-decoration: none;
}

.list-card-main-title-statusBox-green {
  background-color: #03331c;
  margin: 26px;
  padding: 4px;
  font-size: 10px;
  float: left;
  line-height: 13px;
  border-radius: 2px;
  color: #00C162;
  letter-spacing: 1.6px;
}

#table-detail {
  .col1 {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }
  }

  .col2 {
    flex-grow: 2;
  }

  .col3 {
    min-width: 300px;
    width: auto;
    white-space: nowrap;
  }
}

.table-card {
  width: 250px;
  margin-left: 0;
  margin-right: 15px;
  margin-top: 15px;
  height: 150px;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    outline: none;
  }

}

// make tables for mobile
@media (max-width: 767px) {

  .table-list-content {
    .confirm-buttons {
      margin-left: 0.2rem !important;
      margin-right: 0.2rem !important;
      justify-content: space-around !important;
      width: inherit !important;
    }
  }

  .list-card-closed {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .list-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table-card {
    width: 45%;
    margin: 0.5rem;
    height: auto;
  }

  .list-card-main-title-text {
    font-size: 18px;
  }

  .list-card-opened-title {
    font-size: 70%;
  }
}