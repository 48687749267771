$backgroundColor: #170a3a;
$color-lightGray2: rgb(194, 194, 194);
$color-violet: #5b38b9;
$color-violen-dark: #0d0327;
$color-white: #ffffff;

.submenu {
  background-color: $backgroundColor;
  max-height: 100%;
  overflow: auto;
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
  }

  li {
    list-style-type: none;

    >a {
      margin: 0;

      svg,
      img {
        width: 1.2rem !important;
        height: 1.2rem;
        vertical-align: middle;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .nav-link {
    color: $color-lightGray2 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .nav-item {
    color: $color-lightGray2 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

    &.group {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .help {
    margin-top: auto !important;
    margin-bottom: 5px !important;
    z-index: 1;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .btn-back {
    height: auto;
    padding: 5px 25px 5px 25px;
    width: fit-content;
    color: $color-lightGray2;
  }

  .line {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: calc(100% - 1rem);
  }

  .card-icon {
    fill: $color-violet;
    color: $color-violet;
  }
}

.custom-navlink {
  padding-left: 15px;
  margin-left: 0;

  &.disabled {
    color: gray !important;
  }

  &.selected {
    color: $color-white !important;
    font-weight: 500;
    border-right: 0.3rem solid $color-violet;
  }

  &.isOpen {
    background-color: $color-violen-dark;
  }

  .notify-number {

    display: flex;
    justify-content: space-between;
    width: 100%;

    .number {
      background-color: $color-violet;
      border-radius: 50%;
      height: 25px;
      min-width: 25px;
      text-align: center;
      justify-content: center;
    }
  }
}

.custom-collapse {
  background-color: $color-violen-dark;

  .nav-link,
  .nav-item {
    margin-left: 15px;
  }
}

